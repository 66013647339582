import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { GeneralCode } from "../../../../../constant/GeneralCode"
import { Routers } from "../../../../../constant/Routers"
import { Translate } from "../../../../../constant/Translate"
import { BreadcrumbsLayoutTitleType } from "../../models/manual"

const {
  BRCR_SCR991_AUTHORITY_PATTERN,
  BRCR_SCR991_CHANGE_APPLICATION_FORM,
  BRCR_SCR991_FAQ,
  BRCR_SCR991_REGISTRATION_APPLICATION_FORM,
  BRCR_SCR991_SAFETY_MEASURES,
  BRCR_SCR991_SUMMER_TIME,
  BRCR_SCR991_SYSTEM_MANUAL,
  BRCR_SCR991_TEMPLATE,
  BRCR_SCR991_USER,
} = Breadcrumbs
const {
  SCR991_AUTHORITY_PATTERN,
  SCR991_CHANGE_APPLICATION_FORM,
  SCR991_FAQ,
  SCR991_REGISTRATION_APPLICATION_FORM,
  SCR991_SAFETY_MEASURES,
  SCR991_SUMMER_TIME,
  SCR991_SYSTEM_MANUAL,
  SCR991_TEMPLATE,
  SCR991_USER,
} = Routers
const {
  TEXT_T297,
  TEXT_T302,
  TEXT_T345,
  TEXT_T324,
  TEXT_T350,
  TEXT_T691,
  TEXT_T692,
  TEXT_T693,
  TEXT_T694,
} = Translate
const {
  AUTHORITY_PATTERN_TYPE,
  CHANGE_APPLICATION_FORM_TYPE,
  FAQ_TYPE,
  REGISTRATION_APPLICATION_FORM_TYPE,
  SAFETY_MEASURES_TYPE,
  SUBADMIN_MANUAL_TYPE,
  SUMMER_TIME_TYPE,
  TEMPLATE_TYPE,
  USER_MANUAL_TYPE,
} = GeneralCode

const BREADCRUMB_ITEMS: { [key: string]: BreadcrumbsLayoutTitleType } = {
  [SCR991_AUTHORITY_PATTERN]: {
    breadcrumbItems: BRCR_SCR991_AUTHORITY_PATTERN,
    layoutTitle: TEXT_T345,
    type: AUTHORITY_PATTERN_TYPE,
  },
  [SCR991_CHANGE_APPLICATION_FORM]: {
    breadcrumbItems: BRCR_SCR991_CHANGE_APPLICATION_FORM,
    layoutTitle: TEXT_T324,
    type: CHANGE_APPLICATION_FORM_TYPE,
  },
  [SCR991_FAQ]: {
    breadcrumbItems: BRCR_SCR991_FAQ,
    layoutTitle: TEXT_T693,
    type: FAQ_TYPE,
  },
  [SCR991_REGISTRATION_APPLICATION_FORM]: {
    breadcrumbItems: BRCR_SCR991_REGISTRATION_APPLICATION_FORM,
    layoutTitle: TEXT_T302,
    type: REGISTRATION_APPLICATION_FORM_TYPE,
  },
  [SCR991_SAFETY_MEASURES]: {
    breadcrumbItems: BRCR_SCR991_SAFETY_MEASURES,
    layoutTitle: TEXT_T694,
    type: SAFETY_MEASURES_TYPE,
  },
  [SCR991_SUMMER_TIME]: {
    breadcrumbItems: BRCR_SCR991_SUMMER_TIME,
    layoutTitle: TEXT_T350,
    type: SUMMER_TIME_TYPE,
  },
  [SCR991_SYSTEM_MANUAL]: {
    breadcrumbItems: BRCR_SCR991_SYSTEM_MANUAL,
    layoutTitle: TEXT_T692,
    type: SUBADMIN_MANUAL_TYPE,
  },
  [SCR991_TEMPLATE]: {
    breadcrumbItems: BRCR_SCR991_TEMPLATE,
    layoutTitle: TEXT_T297,
    type: TEMPLATE_TYPE,
  },
  [SCR991_USER]: {
    breadcrumbItems: BRCR_SCR991_USER,
    layoutTitle: TEXT_T691,
    type: USER_MANUAL_TYPE,
  },
}

export const ConstantManual = {
  BREADCRUMB_ITEMS,
}
