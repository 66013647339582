const CODE_MESSAGE_W0001 = "MESSAGE.W0001"
const CODE_MESSAGE_W0002 = "MESSAGE.W0002"
const CODE_MESSAGE_W0003 = "MESSAGE.W0003"
const CODE_MESSAGE_W0004 = "MESSAGE.W0004"
const CODE_MESSAGE_W0005 = "MESSAGE.W0005"
const CODE_MESSAGE_W0006 = "MESSAGE.W0006"
const CODE_MESSAGE_W0007 = "MESSAGE.W0007"
const CODE_MESSAGE_W0008 = "MESSAGE.W0008"
const CODE_MESSAGE_W0009 = "MESSAGE.W0009"
const CODE_MESSAGE_W0010 = "MESSAGE.W0010"
const CODE_MESSAGE_W0011 = "MESSAGE.W0011"
const CODE_MESSAGE_W0012 = "MESSAGE.W0012"
const CODE_MESSAGE_W0013 = "MESSAGE.W0013"
const CODE_MESSAGE_W0014 = "MESSAGE.W0014"
const CODE_MESSAGE_W0015 = "MESSAGE.W0015"
const CODE_MESSAGE_W0016 = "MESSAGE.W0016"
const CODE_MESSAGE_W0017 = "MESSAGE.W0017"

export const I18nCodeMessageWarning = {
  CODE_MESSAGE_W0001,
  CODE_MESSAGE_W0002,
  CODE_MESSAGE_W0003,
  CODE_MESSAGE_W0004,
  CODE_MESSAGE_W0005,
  CODE_MESSAGE_W0006,
  CODE_MESSAGE_W0007,
  CODE_MESSAGE_W0008,
  CODE_MESSAGE_W0009,
  CODE_MESSAGE_W0010,
  CODE_MESSAGE_W0011,
  CODE_MESSAGE_W0012,
  CODE_MESSAGE_W0013,
  CODE_MESSAGE_W0014,
  CODE_MESSAGE_W0015,
  CODE_MESSAGE_W0016,
  CODE_MESSAGE_W0017,
}
