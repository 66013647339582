/* eslint-disable max-lines */
import { useEffect } from "react"
import { InitialValues } from "../../../../constants/edit/initialValues"
import { TravelersEditColumnType } from "../../../../models/edit"
import { TableRowSelection } from "../../../../../../../_webui/layout/components/Table/models"
import { CheckboxAll } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxAll"
import { CheckboxItem } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"
import { useVariablesRowSelection } from "../useVariablesRowSelection"
import { CheckboxChangeEvent } from "antd/lib/checkbox"

const { TRAVELERS_EDIT_ACCOMPANIES } = InitialValues

export const useRowSelection = () => {
  const {
    accompanies,
    accompaniesTemp,
    indexTabActive,
    isCheckAll,
    isDisabledBtnDelete,
    isDisabledCheckAll,
    selectedRowKeys,
    setAccompanies,
    setAccompaniesTemp,
    setIndexTabActive,
    setIsCheckAll,
    setIsDisabledBtnDelete,
    setIsDisabledCheckAll,
    setSelectedRowKeys,
    setTotal,
    total,
  } = useVariablesRowSelection()

  const onSelectChange = () => {
    // 画面上のボタンを活性／非活性にする。
    const _isShowBtnDelete = !selectedRowKeys.length
    setIsDisabledBtnDelete(_isShowBtnDelete)

    if (selectedRowKeys.length === accompanies.length) setIsCheckAll(true)
    else setIsCheckAll(false)
  }

  const onChangeCheckAll = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setIsCheckAll(checked)
    if (checked) {
      const _selectedRowKeys = accompanies.map((item) => item.index)
      setSelectedRowKeys(_selectedRowKeys)
    } else {
      setSelectedRowKeys([])
    }
  }

  const onChangeCheckItem = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, value])
    } else {
      const _selectedRowKeys = selectedRowKeys.filter((item) => item !== value)
      setSelectedRowKeys(_selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<TravelersEditColumnType> = {
    columnTitle: (
      <CheckboxAll
        checked={isCheckAll}
        isDisabled={isDisabledCheckAll}
        onChange={onChangeCheckAll}
      />
    ),
    renderCell: (_, record) => (
      <CheckboxItem
        checked={selectedRowKeys.includes(record.index)}
        onChange={onChangeCheckItem}
        value={record.index}
      />
    ),
    selectedRowKeys,
  }

  const onTabClick = (activeKey: string) => {
    setIndexTabActive(activeKey)
  }

  // 新規タブを生成する。
  const addItems = () => {
    const index = total + 1
    setTotal(index)
    const familyId = `tempId${new Date().getTime().toString()}`
    const result: TravelersEditColumnType[] = [
      ...accompanies,
      {
        ...TRAVELERS_EDIT_ACCOMPANIES,
        familyId,
        index: index,
      },
    ]
    setAccompanies(result)
    setIndexTabActive((total + 2).toString())

    if (typeof index === "number") {
      setTimeout(() => {
        document.getElementById(`familyRomajiSurName_${index}`)?.focus()
      }, 100)
    }
  }

  // タブを削除する。
  const removeItems = (index?: number) => {
    if (index === 0 || index) {
      const _accompanies = accompanies.filter((item) => item.index !== index)

      setAccompanies(_accompanies)
      setIndexTabActive((_accompanies[0]?.index + 1)?.toString() ?? "1")
    } else {
      const _accompanies = accompanies.filter((item) => {
        return !selectedRowKeys.some((value) => item.index === value)
      })
      setAccompanies(_accompanies)
    }
    setIsDisabledBtnDelete(true)

    setTimeout(() => {
      const elmRows = document.querySelectorAll(
        "#table-travelers-edit-accompanies tbody > tr",
      )
      const emlInput = elmRows[elmRows.length - 1]?.querySelector(
        "td:nth-child(3) input",
      ) as HTMLInputElement
      if (emlInput) emlInput?.focus()
      else document.getElementById("table-travelers-edit-accompanies")?.focus()
    }, 100)
  }

  useEffect(() => {
    if (selectedRowKeys?.length) onSelectChange()
  }, [selectedRowKeys])

  useEffect(() => {
    if (!accompanies.length) {
      setIsCheckAll(false)
      setIsDisabledCheckAll(true)
    } else {
      setIsDisabledCheckAll(false)
    }
    document
      .getElementById("table-travelers-edit-accompanies")
      ?.setAttribute("tabindex", "0")
  }, [accompanies])

  return {
    accompanies,
    accompaniesTemp,
    addItems,
    indexTabActive,
    isDisabledBtnDelete,
    onTabClick,
    removeItems,
    rowSelection,
    setAccompanies,
    setAccompaniesTemp,
    setTotal,
  }
}
