import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { getAllCountries } from "../../../../../../../utils/apis/getAllCountries"
import { getCities } from "../../../../../../../utils/apis/getCities"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"

const { DPTD, PRFT, TPTT } = GeneralCode

export const useVariablesUtils = (countryCode: string) => {
  // APIを呼び出し、「渡航国」のデータ一覧を取得する。
  const { allCountries } = getAllCountries(true)
  const { cities } = getCities(countryCode)
  // APIを呼び出し、マスタから「派遣種別」のデータ一覧を取得する。
  const { generalMastersAll: dispatchTypeAll } = getGeneralMasters(DPTD)
  // APIを呼び出し、マスタから「居住している都道府県」のデータ一覧を取得する。
  const { generalMasters: personPrefectureResidence } = getGeneralMasters(PRFT)
  // APIを呼び出し、マスタから「交通手段」のデータ一覧を取得する。
  const { generalMasters: transportation } = getGeneralMasters(TPTT)

  return {
    allCountries,
    cities,
    dispatchTypeAll,
    personPrefectureResidence,
    transportation,
  }
}
