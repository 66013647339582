/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import { RadioChangeEvent } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import moment from "moment"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { FullNameType } from "../../../../../../../_webui/layout/components/Typography/FullName/models"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { generalMastersApi } from "../../../../../../../infrastructure/handlers/generalMasters"
import { GeneralMastersResType } from "../../../../../../../infrastructure/handlers/generalMasters/models"
import { checkIsArray } from "../../../../../../../utils/checkIsArray"
import { concat } from "../../../../../../../utils/concat"
import { getFullName } from "../../../../../../../utils/getFullName"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { replaceString } from "../../../../../../../utils/replaceString"
import { setFieldsErrors } from "../../../../../../../utils/setFieldsErrors"
import { travelerApi } from "../../../../apis"
import { Variables } from "../../../../constants/edit/variables"
import {
  TravelersEditFormDataType,
  TravelersEditResType,
} from "../../../../models/edit"
import { useVariablesForm } from "../useVariablesForm"
import { convertDataAccompanies } from "./convertDataAccompanies"
import { convertDataSubmit } from "./convertDataSubmit"
import { getDataAccompanies } from "./getDataAccompanies"
import { handleFormValuesChange } from "./handleFormValuesChange"
import { setFieldsValueAccompanies } from "./setFieldsValueAccompanies"
import { Translate } from "../../../../../../../constant/Translate"
import { replaceStringParam } from "../../../../../../../utils/replaceStringParam"

const { DPTD, DPTD_SUBADMIN, OTHER } = GeneralCode
const { SCR117, SCR973 } = Routers
const { MESSAGE_E0149, TEXT_T054, TEXT_T065, TEXT_T100 } = Translate
const { DISPATCH_TYPE_DETAILS_ASSOCIATION, KEYS_ACCOMPANYING_FAMILY } =
  Variables

export const useForm = (id: string, isRoleSubAdmin: boolean) => {
  const {
    accompanies,
    accompaniesTemp,
    addItems,
    allCountries,
    cities,
    departments,
    dispatchType,
    dispatchTypeDetailId,
    form,
    fullName,
    indexTabActive,
    isAccompanyingFamily,
    isChangeUser,
    isCopyEmailAddress,
    isDifferentInforCheck,
    isDisabledBtnDelete,
    isDisabledSubmit,
    isHiddenTrainingDate,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenPopup,
    isOtherCity,
    isOverseasResident,
    isRequiredUrgentContact,
    listKey1IsAccompanyingFamily,
    listKey1IsValue5,
    onCancel,
    onCancelPopup,
    onOpen,
    onOpenPopup,
    onTabClick,
    othersStaffNumber,
    prefectures,
    push,
    relationships,
    removeItems,
    rowSelection,
    setAccompanies,
    setAccompaniesTemp,
    setCountryCode,
    setDispatchType,
    setDispatchTypeDetailId,
    setFullName,
    setIsAccompanyingFamily,
    setIsChangeUser,
    setIsCopyEmailAddress,
    setIsDifferentInforCheck,
    setIsDisabledSubmit,
    setIsHiddenTrainingDate,
    setIsInsuranceRequired,
    setIsLoading,
    setIsLoadingLayout,
    setIsOtherCity,
    setIsOverseasResident,
    setIsRequiredUrgentContact,
    setListKey1IsAccompanyingFamily,
    setListKey1IsValue5,
    setOthersStaffNumber,
    setSubmitValues,
    setTotal,
    setUserId,
    state,
    submitValues,
    userId,
  } = useVariablesForm()

  // 画面上の入力値のバリデーションチェックを行う。
  const onValuesChange = () => {
    const _isInsuranceRequired = !form
      .getFieldValue("insuranceCompanyName")
      .includes(TEXT_T100)

    setIsInsuranceRequired(_isInsuranceRequired)

    const { formValues } = handleFormValuesChange({
      form,
      isAccompanyingFamily,
      isInsuranceRequired: _isInsuranceRequired,
      listKey1IsAccompanyingFamily,
      othersStaffNumber,
    })

    setIsDisabledSubmit(
      checkIsArray(
        Object.values(formValues).filter(
          (item) => item === null || item === "",
        ),
      ),
    )
    if (userId && userId !== form.getFieldValue("userId")) setIsChangeUser(true)
    else {
      setIsChangeUser(false)
      form.validateFields(["userIdForChangeRequest"])
    }
  }

  // APIを呼び出し、データベースから渡航者情報を取得する。
  const getTravelerDetails = () => {
    // APIを呼び出し、渡航者を取得する。
    const getTravelerDetails: Promise<TravelersEditResType> =
      travelerApi.getTravelerDetails(id)
    // APIを呼び出し、「派遣種別（詳細）」コンボボックスの表示一覧を取得する。
    const getDispatchTypeDetails: Promise<GeneralMastersResType[]> =
      generalMastersApi.getGeneralMaster(DPTD)

    Promise.all([getTravelerDetails, getDispatchTypeDetails])
      .then((res) => {
        const { base, detail, accompanies } = res[0]

        setCountryCode(concat(detail.workPlaceCountry))
        setIsDifferentInforCheck(base.differentInforCheck)
        setIsOtherCity(detail.workPlaceCity === OTHER)
        setIsOverseasResident(detail.workPlaceLocation === 1)
        setUserId(concat(base.userId))
        setIsHiddenTrainingDate(!!detail.trainingDate)
        setOthersStaffNumber(concat(detail.othersStaffNumber))
        setDispatchTypeDetailId(base.dispatchTypeDetailId)

        const _fullName: FullNameType = {
          kanji: concat(base.kanjiSurname, base.kanjiName)
            ? getFullName(base.kanjiSurname, base.kanjiName, true)
            : "",
          romaji: getFullName(base.romajiSurname, base.romajiName),
        }
        setFullName(_fullName)

        if (accompanies) {
          const tableItems = getDataAccompanies(accompanies)
          setAccompanies(tableItems)
          setAccompaniesTemp(tableItems)
        }

        form.setFieldsValue({
          ...base,
          ...detail,
          trainingDate: detail.trainingDate && moment(detail.trainingDate),
        })

        setFieldsValueAccompanies(form, accompanies)
        setTotal(accompanies ? accompanies.length - 1 : 0)

        const optionItem: OptionItemType[] = res[1].map((item) => ({
          text: String(getValueByLanguage(item.value1, item.value2)),
          value: item.key1,
        }))
        const dptdSubadmin = DPTD_SUBADMIN.filter(
          (item) => item !== base.dispatchTypeDetailId,
        )
        const dispatchTypeItems = isRoleSubAdmin
          ? optionItem.filter(
              (item) => !dptdSubadmin.includes(String(item.value)),
            )
          : optionItem
        const _dispatchType =
          detail.othersMemberNumber || detail.othersStaffNumber
            ? dispatchTypeItems?.filter(
                (item) =>
                  !KEYS_ACCOMPANYING_FAMILY.includes(
                    item.value?.toString() || "",
                  ),
              )
            : dispatchTypeItems

        setDispatchType(_dispatchType)

        const _listKey1IsValue5 = res[1]
          .filter((item) => item.value5 === "1")
          .map((item) => item.key1)

        setIsRequiredUrgentContact(
          _listKey1IsValue5.includes(base.dispatchTypeDetailId),
        )

        setListKey1IsValue5(_listKey1IsValue5)

        const _listKey1IsAccompanyingFamily = res[1]
          .filter((item) => KEYS_ACCOMPANYING_FAMILY.includes(item.key1))
          .map((item) => item.key1)

        setIsAccompanyingFamily(
          _listKey1IsAccompanyingFamily.includes(base.dispatchTypeDetailId),
        )

        setListKey1IsAccompanyingFamily(_listKey1IsAccompanyingFamily)
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
      .finally(() => {
        onValuesChange()
      })
  }

  const onSubmit = (values: TravelersEditFormDataType) => {
    const _accompanies = !isAccompanyingFamily
      ? convertDataAccompanies(
          form,
          accompanies,
          accompaniesTemp,
          relationships,
        )
      : []

    const dataSubmit = convertDataSubmit({
      allCountries,
      cities,
      departments,
      dispatchType,
      prefectures,
      travelerId: id,
      values,
    })
    setSubmitValues({ ...dataSubmit, accompanies: _accompanies })
    // 情報更新の確認ダイアログを表示する
    onOpen()
  }

  const onWorkPlaceLocationAndTrainingTypeError = () => {
    form.setFields([
      {
        errors: [replaceStringParam(MESSAGE_E0149, TEXT_T054)],
        name: "workPlaceLocation",
      },
      {
        errors: [replaceStringParam(MESSAGE_E0149, TEXT_T065)],
        name: "trainingType",
      },
    ])
    setTimeout(() => {
      document.querySelectorAll(".ant-collapse-item")[3]?.scrollIntoView()
    }, 300)
  }

  const onWorkPlaceLocationError = () => {
    form.setFields([
      {
        errors: [replaceStringParam(MESSAGE_E0149, TEXT_T054)],
        name: "workPlaceLocation",
      },
    ])
    setTimeout(() => {
      document.querySelectorAll(".ant-collapse-item")[3]?.scrollIntoView()
    }, 300)
  }

  const onTrainingTypeError = () => {
    form.setFields([
      {
        errors: [replaceStringParam(MESSAGE_E0149, TEXT_T065)],
        name: "trainingType",
      },
    ])
    setTimeout(() => {
      document.querySelectorAll(".ant-collapse-item")[6]?.scrollIntoView()
    }, 300)
  }

  const onFinish = (values: TravelersEditFormDataType) => {
    if (KEYS_ACCOMPANYING_FAMILY.includes(values.dispatchTypeDetailId)) {
      if (values.workPlaceLocation !== 0 && values.trainingType !== 0) {
        return onWorkPlaceLocationAndTrainingTypeError()
      }
      if (values.workPlaceLocation !== 0) {
        return onWorkPlaceLocationError()
      }
      if (values.trainingType !== 0) {
        return onTrainingTypeError()
      }
      onSubmit(values)
    } else {
      if (values.workPlaceLocation === 0 && values.trainingType === 0) {
        return onWorkPlaceLocationAndTrainingTypeError()
      }
      if (values.workPlaceLocation === 0) {
        return onWorkPlaceLocationError()
      }
      onSubmit(values)
    }
  }

  const onOk = () => {
    setIsLoading(true)
    if (typeof submitValues === "object") {
      // APIを呼び出し、渡航者情報をデータベースに更新する。
      travelerApi
        .updateTraveler(id, submitValues)
        .then(() => {
          push(replaceString(SCR117, id), {
            fullName,
            isFromSCR113: state?.isFromSCR113,
            isFromSCR116: true,
            userIdForChangeRequest: submitValues.detail.userIdForChangeRequest,
          })
        })
        .catch((error: AxiosErrorType) => {
          if (error?.debug?.response?.data?.entityName) {
            setFieldsErrors(error, form)
            onCancel()
            setIsLoading(false)
          } else {
            // 「SCR973_エラー情報の表示画面」に遷移する。
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          }
        })
    }
  }

  const onWorkPlaceLocationChange = (event: RadioChangeEvent) => {
    const { value } = event.target
    setIsOverseasResident(value === 1)
  }

  const onChangeTrainingType = (event: RadioChangeEvent) => {
    const { value } = event.target
    setIsHiddenTrainingDate(!!value)
    if (!value) form.setFieldValue("trainingDate", "")
  }

  const onWorkPlaceCountryChange = (value: string) => {
    setCountryCode(value)
    form.setFieldValue("workPlaceCity", "")
    onWorkPlaceCityChange("")
  }

  const onWorkPlaceCityChange = (value: string) => {
    const _isOtherCity = value === OTHER
    setIsOtherCity(_isOtherCity)
    form.setFieldValue("workPlace", "")

    onValuesChange()
  }

  // userIDのメールを個人メールにコピー＆ペーストする。
  const onCopyEmailAddress = (event: CheckboxChangeEvent) => {
    const isChecked = event.target.checked

    if (isChecked) form.setFieldValue("personEmailAddress", userId)

    setIsCopyEmailAddress(isChecked)
  }

  const onDifferentInforCheckChange = (event: CheckboxChangeEvent) => {
    setIsDifferentInforCheck(event.target.checked)
  }

  const onDispatchTypeDetailChange = (value: string) => {
    setIsRequiredUrgentContact(listKey1IsValue5.includes(value))
    form.setFields([
      {
        errors: [],
        name: "workPlaceLocation",
      },
      {
        errors: [],
        name: "trainingType",
      },
    ])

    setIsAccompanyingFamily(listKey1IsAccompanyingFamily.includes(value))
    setDispatchTypeDetailId(value)
    if (DISPATCH_TYPE_DETAILS_ASSOCIATION.includes(value)) {
      if (form.getFieldValue("insuranceCompanyName") !== TEXT_T100) {
        onOpenPopup()
        form.setFieldValue("insuranceCompanyName", TEXT_T100)
      }
    } else if (
      DISPATCH_TYPE_DETAILS_ASSOCIATION.includes(dispatchTypeDetailId)
    ) {
      form.setFieldsValue({
        insuranceCompanyName: "",
        insuranceCompanyPhoneNumber: "",
        insuranceSonsignor: "",
        insuranceTicketNumber: "",
      })
    }
    onValuesChange()
  }

  const onOkPopup = () => {
    onCancelPopup()
    form.setFieldsValue({
      insuranceCompanyPhoneNumber: "",
      insuranceSonsignor: "",
      insuranceTicketNumber: "",
    })
  }

  return {
    accompanies,
    addItems,
    allCountries,
    cities,
    departments,
    dispatchType,
    form,
    fullName,
    getTravelerDetails,
    indexTabActive,
    isAccompanyingFamily,
    isChangeUser,
    isCopyEmailAddress,
    isDifferentInforCheck,
    isDisabledBtnDelete,
    isDisabledSubmit,
    isHiddenTrainingDate,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenPopup,
    isOtherCity,
    isOverseasResident,
    isRequiredUrgentContact,
    listKey1IsAccompanyingFamily,
    onCancel,
    onChangeTrainingType,
    onCopyEmailAddress,
    onDifferentInforCheckChange,
    onDispatchTypeDetailChange,
    onFinish,
    onOk,
    onOkPopup,
    onTabClick,
    onValuesChange,
    onWorkPlaceCityChange,
    onWorkPlaceCountryChange,
    onWorkPlaceLocationChange,
    othersStaffNumber,
    prefectures,
    relationships,
    removeItems,
    rowSelection,
  }
}
