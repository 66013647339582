const CODE_MESSAGE_I0001 = "MESSAGE.I0001"
const CODE_MESSAGE_I0002 = "MESSAGE.I0002"
const CODE_MESSAGE_I0003 = "MESSAGE.I0003"
const CODE_MESSAGE_I0004 = "MESSAGE.I0004"
const CODE_MESSAGE_I0005 = "MESSAGE.I0005"
const CODE_MESSAGE_I0006 = "MESSAGE.I0006"
const CODE_MESSAGE_I0007 = "MESSAGE.I0007"
const CODE_MESSAGE_I0008 = "MESSAGE.I0008"
const CODE_MESSAGE_I0009 = "MESSAGE.I0009"
const CODE_MESSAGE_I0010 = "MESSAGE.I0010"
const CODE_MESSAGE_I0011 = "MESSAGE.I0011"
const CODE_MESSAGE_I0012 = "MESSAGE.I0012"
const CODE_MESSAGE_I0013 = "MESSAGE.I0013"
const CODE_MESSAGE_I0014 = "MESSAGE.I0014"
const CODE_MESSAGE_I0015 = "MESSAGE.I0015"
const CODE_MESSAGE_I0016 = "MESSAGE.I0016"
const CODE_MESSAGE_I0017 = "MESSAGE.I0017"
const CODE_MESSAGE_I0018 = "MESSAGE.I0018"
const CODE_MESSAGE_I0019 = "MESSAGE.I0019"
const CODE_MESSAGE_I0020 = "MESSAGE.I0020"
const CODE_MESSAGE_I0021 = "MESSAGE.I0021"
const CODE_MESSAGE_I0022 = "MESSAGE.I0022"
const CODE_MESSAGE_I0023 = "MESSAGE.I0023"
const CODE_MESSAGE_I0024 = "MESSAGE.I0024"
const CODE_MESSAGE_I0025 = "MESSAGE.I0025"

export const I18nCodeMessageInfo = {
  CODE_MESSAGE_I0001,
  CODE_MESSAGE_I0002,
  CODE_MESSAGE_I0003,
  CODE_MESSAGE_I0004,
  CODE_MESSAGE_I0005,
  CODE_MESSAGE_I0006,
  CODE_MESSAGE_I0007,
  CODE_MESSAGE_I0008,
  CODE_MESSAGE_I0009,
  CODE_MESSAGE_I0010,
  CODE_MESSAGE_I0011,
  CODE_MESSAGE_I0012,
  CODE_MESSAGE_I0013,
  CODE_MESSAGE_I0014,
  CODE_MESSAGE_I0015,
  CODE_MESSAGE_I0016,
  CODE_MESSAGE_I0017,
  CODE_MESSAGE_I0018,
  CODE_MESSAGE_I0019,
  CODE_MESSAGE_I0020,
  CODE_MESSAGE_I0021,
  CODE_MESSAGE_I0022,
  CODE_MESSAGE_I0023,
  CODE_MESSAGE_I0024,
  CODE_MESSAGE_I0025,
}
