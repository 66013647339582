/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import {
  TravelersListDataType,
  UseTravelersListType,
} from "../../../../models/list"
import { useVariables } from "../useVariables"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { TravelersListDataSourceType } from "../../../../models/list/data"
import { convertDataSource } from "./convertDataSource"
import { travelerApi } from "../../../../apis"
import { Translate } from "../../../../../../../constant/Translate"
import { replaceStringParam } from "../../../../../../../utils/replaceStringParam"
import { Variables } from "../../../../constants/list/variables"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { departmentsApi } from "../../../../../../../infrastructure/handlers/departments"
import { DepartmentsResType } from "../../../../../../../infrastructure/handlers/departments/models"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { TravelersUserSearchFormDataType } from "../../../../components/list/FormSearch/modals"
import { convertDataSearch } from "./convertDataSearch"

const { SCR973 } = Routers
const { MESSAGE_I0022 } = Translate
const { TRAVELERS_LIST_OVER_RECORD } = Variables

export const useTravelersList =
  (): UseTravelersListType<TravelersListDataSourceType> => {
    const {
      breadcrumbItems,
      columns,
      dataTemp,
      departments,
      form,
      goToSchedulesAdd,
      goToTravelersDelete,
      goToTravelersDetail,
      goToTravelersSearch,
      handleExportFile,
      isDisabledBtnDelete,
      isDisabledBtnRefer,
      isHiddenBtnDelete,
      isHiddenBtnExportFile,
      isHiddenBtnRefer,
      isHiddenBtnRegister,
      isHiddenBtnRequestDelete,
      isHiddenBtnSearch,
      isLoading,
      isLoadingLayout,
      isNotAccessScreen,
      isOpen,
      isOpenLoadData,
      isOpenShowData,
      isPathnameTravelersUserSearch,
      onCancel,
      onCancelLoadData,
      onCancelShowData,
      onChange,
      onChangeTable,
      onOpen,
      onOpenLoadData,
      onOpenShowData,
      overRecordLabel,
      perItem,
      push,
      rowSelection,
      searchCondition,
      setDataSourceExport,
      setDataTemp,
      setDepartments,
      setIsLoading,
      setIsLoadingLayout,
      setOverRecordLabel,
      setSearchCondition,
      setTravelerInfo,
      state,
      travelerInfo,
    } = useVariables()

    const getAllDepartments = () => {
      // APIを呼び出し、部門一覧情報を取得し
      departmentsApi
        .getAllDepartments()
        .then((res: DepartmentsResType[]) => {
          const optionItem: OptionItemType[] = res.map((item) => ({
            text: getValueByLanguage(
              item.departmentNameJapan,
              item.departmentNameEnglish,
            ).toString(),
            value: item.bumonCd,
          }))
          setDepartments(optionItem)
        })
        .catch((error: AxiosErrorType) => {
          const state = pushStateError(error)
          push({ pathname: SCR973, state })
        })
        .finally(() => {
          if (!state?.searchCondition) {
            setIsLoadingLayout(false)
          }
        })
    }

    const browseTravelers = (values?: TravelersUserSearchFormDataType) => {
      const dataConvert = convertDataSearch(values)
      // 渡航者情報一覧を取得する。
      const data = values
        ? dataConvert
        : { ...state?.searchCondition, isGetAllData: false }
      isPathnameTravelersUserSearch && setSearchCondition(data)

      travelerApi
        .browseTravelers(data)
        .then((res: TravelersListDataType[]) => {
          const _res = convertDataSource(res)
          if (res.length > TRAVELERS_LIST_OVER_RECORD) {
            setDataSourceExport(res.slice(0, TRAVELERS_LIST_OVER_RECORD))
            if (state?.searchCondition || values) {
              onOpenLoadData()
              setDataTemp(_res)
            } else {
              setOverRecordLabel(
                replaceStringParam(MESSAGE_I0022, TRAVELERS_LIST_OVER_RECORD),
              )
              setTravelerInfo(_res.slice(0, TRAVELERS_LIST_OVER_RECORD))
            }
          } else {
            // 画面上の渡航者情報初期表示処理を行う。
            setTravelerInfo(_res)
            setDataSourceExport(res)
            setOverRecordLabel("")
          }
          setIsLoading(false)
          setIsLoadingLayout(false)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
    }
    const onFinish = (values?: TravelersUserSearchFormDataType) => {
      setIsLoading(true)
      browseTravelers(values)
    }

    const onOkLoadData = () => {
      setIsLoading(true)
      onCancelLoadData()
      const dataSearch = searchCondition || state?.searchCondition
      const data = { ...dataSearch, isGetAllData: true }
      // 渡航者情報一覧を取得する。
      travelerApi
        .browseTravelers(data)
        .then((res: TravelersListDataType[]) => {
          const _res = convertDataSource(res)
          setOverRecordLabel("")
          setTravelerInfo(_res)
          setDataSourceExport(res)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    const onCancelCustomLoadData = () => {
      onCancelLoadData()
      onOpenShowData()
    }

    const onOkShowData = () => {
      const _dataTemp = dataTemp.slice(0, TRAVELERS_LIST_OVER_RECORD)
      setTravelerInfo(_dataTemp)
      onCancelShowData()
      setOverRecordLabel(
        replaceStringParam(MESSAGE_I0022, TRAVELERS_LIST_OVER_RECORD),
      )
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (isNotAccessScreen) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else if (!isPathnameTravelersUserSearch || state.searchCondition)
        browseTravelers()
      if (isPathnameTravelersUserSearch) {
        getAllDepartments()
        if (state?.searchCondition) {
          form.setFieldsValue({
            ...state?.searchCondition.base,
            fullName: state.searchCondition.fullName,
          })
        }
      }
    }, [])

    return {
      breadcrumbItems,
      columns,
      dataSource: travelerInfo,
      departments,
      form,
      goToSchedulesAdd,
      goToTravelersDelete,
      goToTravelersDetail,
      goToTravelersSearch,
      handleExportFile,
      isDisabledBtnDelete,
      isDisabledBtnRefer,
      isHiddenBtnDelete,
      isHiddenBtnExportFile,
      isHiddenBtnRefer,
      isHiddenBtnRegister,
      isHiddenBtnRequestDelete,
      isHiddenBtnSearch,
      isLoading,
      isLoadingLayout,
      isOpen,
      isOpenLoadData,
      isOpenShowData,
      isPathnameTravelersUserSearch,
      onCancel,
      onCancelCustomLoadData,
      onChange,
      onChangeTable,
      onFinish,
      onOkLoadData,
      onOkShowData,
      onOpen,
      overRecordLabel,
      perItem,
      rowSelection,
    }
  }
