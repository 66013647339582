import { useState } from "react"
import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { getAllCountries } from "../../../../../../../../utils/apis/getAllCountries"
import { getGeneralMasters } from "../../../../../../../../utils/apis/getGeneralMasters"
import { OptionItemType } from "../../../../../../../../_webui/layout/components/Select/models"

const { TYPE } = GeneralCode

export const useVariablesUtils = () => {
  const [fileTypeList, setFileTypeList] = useState<OptionItemType[]>([])
  // APIを呼び出し、国情報一覧を取得し
  const { allCountries } = getAllCountries()
  //  APIを呼び出し、種類一覧を取得し
  const { generalMasters: typeInfo } = getGeneralMasters(TYPE)

  return {
    allCountries,
    fileTypeList,
    setFileTypeList,
    typeInfo,
  }
}
