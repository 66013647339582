/* eslint-disable max-lines */
import { useEffect } from "react"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { TravelersConfirmDeleteType } from "../../../../models/list"
import { TravelersListDataSourceType } from "../../../../models/list/data"
import { TableRowSelection } from "../../../../../../../_webui/layout/components/Table/models"
import { CheckboxAll } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxAll"
import { CheckboxItem } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"
import { useVariablesRowSelection } from "../useVariablesRowSelection"
import { TableCurrentDataSource } from "antd/lib/table/interface"

export const useRowSelection = (
  travelerInfo: TravelersListDataSourceType[],
) => {
  const {
    dataCurrent,
    dataSource,
    dataSourceSelected,
    isCheckAll,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setDataSource,
    setDataSourceSelected,
    setIsCheckAll,
    setIsDisabledBtnDelete,
    setIsDisabledBtnRefer,
    setSelectedRowKeys,
    setTravelerId,
    travelerId,
  } = useVariablesRowSelection(travelerInfo.length)

  const onSelectChange = () => {
    const selectedRows = dataCurrent.filter((item) =>
      selectedRowKeys.includes(item.travelerId),
    )
    const newSelectedRows: TravelersConfirmDeleteType[] = selectedRows.map(
      (item) => ({
        dispatchTypeDetail: item.dispatchTypeDetailName,
        fullNameKanji: item.fullNameKanji,
        fullNameRomaji: item.fullNameRomaji,
        travelerId: item.travelerId,
      }),
    )

    // 画面上のボタンを活性／非活性にする。
    const cantDelete = selectedRows.length
      ? selectedRows.find((item) => Number(item.systemIdentifier))
      : true
    const cantRefer = selectedRows.length !== 1
    const idSelected = selectedRows.length ? selectedRows[0].travelerId : ""

    setDataSourceSelected(newSelectedRows)
    setIsDisabledBtnDelete(Boolean(cantDelete))
    setIsDisabledBtnRefer(cantRefer)
    setTravelerId(idSelected)
    if (selectedRowKeys.length === dataCurrent.length) {
      setIsCheckAll(true)
    } else {
      setIsCheckAll(false)
    }
  }

  const onChangeCheckAll = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setIsCheckAll(checked)
    if (checked) {
      const _selectedRowKeys = dataCurrent.map((item) => item.travelerId)
      setSelectedRowKeys(_selectedRowKeys)
    } else {
      setSelectedRowKeys([])
    }
  }

  const onChangeCheckItem = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, value])
    } else {
      const _selectedRowKeys = selectedRowKeys.filter((item) => item !== value)
      setSelectedRowKeys(_selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<TravelersListDataSourceType> = {
    columnTitle: (
      <CheckboxAll
        checked={isCheckAll}
        isDisabled={!dataSource.length}
        onChange={onChangeCheckAll}
      />
    ),
    renderCell: (_, record) => (
      <CheckboxItem
        checked={selectedRowKeys.includes(record.travelerId)}
        onChange={onChangeCheckItem}
        value={record.travelerId}
      />
    ),
    selectedRowKeys: selectedRowKeys,
  }

  const onChange = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setIsCheckAll(false)
    setIsDisabledBtnDelete(true)
    setIsDisabledBtnRefer(true)
    setSelectedRowKeys([])
  }

  const onChangeTable = (
    extra: TableCurrentDataSource<TravelersListDataSourceType>,
  ) => {
    setDataSource(extra.currentDataSource)
    setIsCheckAll(false)
    setIsDisabledBtnDelete(true)
    setIsDisabledBtnRefer(true)
    setSelectedRowKeys([])
  }

  useEffect(() => {
    if (dataSource?.length) {
      const _dataCurrent = dataSource.slice(perItemStart, perItemEnd)
      setDataCurrent(_dataCurrent)
    }
  }, [dataSource, setDataCurrent, perItemStart, perItemEnd])

  useEffect(() => {
    if (dataSource?.length) {
      onSelectChange()
    }
  }, [selectedRowKeys])

  useEffect(() => {
    if (travelerInfo?.length) {
      setDataSource(travelerInfo)
    }
  }, [travelerInfo])

  return {
    dataSourceSelected,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    travelerId,
  }
}
