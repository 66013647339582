/* eslint-disable max-lines */
import { Key } from "react"
import { Endpoint } from "../../../../constant/Endpoint"
import { Environment } from "../../../../constant/Environment"
import { apiService } from "../../../../infrastructure/apiService"
import { TimezoneResType } from "../../../../infrastructure/handlers/timezone/models"
import { replaceStringParam } from "../../../../utils/replaceStringParam"
import { SubAdminSettingFormDataPostType } from "../../settings/models/subAdmin/setting"
import { TravelersFormDataType } from "../models/add"
import { TravelersEditResType } from "../models/edit"
import { TravelersListPostType } from "../models/list"
import { TravelersRequestDeleteReqType } from "../models/requestDelete"
import { TravelersBatchImportFormType } from "../models/batchImport"

const { TRAVELER_ENDPOINT } = Environment
const {
  FNC1A_Accept_Request_Disable,
  FNC1A_Add_Traveler,
  FNC1A_Browse_Travelers,
  FNC1A_Bulk_Add,
  FNC1A_Bulk_Check,
  FNC1A_Bulk_Data,
  FNC1A_Bulk_Delete_Data,
  FNC1A_Bulk_Delete,
  FNC1A_Bulk_File_Add,
  FNC1A_Bulk_File_Delete,
  FNC1A_Bulk_File_Update,
  FNC1A_Bulk_Request_Delete,
  FNC1A_Bulk_Update,
  FNC1A_Cancel_Request_Disable,
  FNC1A_Count,
  FNC1A_Disable_Traveler,
  FNC1A_Disable_Travelers,
  FNC1A_Enable,
  FNC1A_Export,
  FNC1A_Get_Traveler_Base,
  FNC1A_Get_Traveler_Details,
  FNC1A_Grant_Authority_Pattern,
  FNC1A_Request_Browse_Travelers,
  FNC1A_Request_Enable,
  FNC1A_Request_To_Disable_Traveler,
  FNC1A_Request_To_Disable_Travelers,
  FNC1A_Update_Timezone,
  FNC1A_Update_Traveler,
} = Endpoint

export const travelerApi = {
  acceptRequestDisable: (listTravelers: Key[]) =>
    apiService({
      data: listTravelers,
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Accept_Request_Disable}`,
    }),
  addTraveler: (data: TravelersFormDataType) =>
    apiService({
      data,
      method: "POST",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Add_Traveler}`,
    }),
  browseTravelers: (data?: TravelersListPostType) =>
    apiService({
      data,
      method: "POST",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Browse_Travelers}`,
    }),
  bulkAdd: (data: TravelersBatchImportFormType) =>
    apiService({
      data,
      method: "POST",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Bulk_Add}`,
    }),

  bulkCheck: (screenId: string) =>
    apiService({
      method: "GET",
      url: `${TRAVELER_ENDPOINT}${replaceStringParam(
        FNC1A_Bulk_Check,
        screenId,
      )}`,
    }),

  bulkData: (screenId: string) =>
    apiService({
      method: "GET",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Bulk_Data}?screenId=${screenId}`,
    }),
  bulkDelete: (screenId: string) =>
    apiService({
      data: screenId,
      method: "DELETE",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Bulk_Delete}`,
    }),
  bulkDeleteData: (screenId: string) =>
    apiService({
      method: "DELETE",
      url: `${TRAVELER_ENDPOINT}${replaceStringParam(
        FNC1A_Bulk_Delete_Data,
        screenId,
      )}`,
    }),
  bulkFileAdd: (data: FormData) =>
    apiService({
      data,
      method: "POST",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Bulk_File_Add}`,
    }),

  bulkFileDelete: (data: FormData) =>
    apiService({
      data,
      method: "POST",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Bulk_File_Delete}`,
    }),

  bulkFileUpdate: (data: FormData) =>
    apiService({
      data,
      method: "POST",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Bulk_File_Update}`,
    }),

  bulkRequestDelete: (screenId: string) =>
    apiService({
      data: screenId,
      method: "DELETE",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Bulk_Request_Delete}`,
    }),

  bulkUpdate: (data: TravelersBatchImportFormType) =>
    apiService({
      data,
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Bulk_Update}`,
    }),

  cancelRequestDisable: (listTravelers: Key[]) =>
    apiService({
      data: listTravelers,
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Cancel_Request_Disable}`,
    }),
  count: () =>
    apiService({
      method: "GET",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Count}?requestType=Disabled,RequestDisable,RequestEnable`,
    }),
  disableTraveler: (id: string) =>
    apiService({
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${replaceStringParam(
        FNC1A_Disable_Traveler,
        id,
      )}`,
    }),
  disableTravelers: (data: string[]) =>
    apiService({
      data,
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Disable_Travelers}`,
    }),
  enable: (listTravelers: Key[]) =>
    apiService({
      data: listTravelers,
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Enable}`,
    }),
  export: (data: Key[]) =>
    apiService({
      data,
      method: "POST",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Export}`,
    }),
  getTravelerBase: (travelerId: string) =>
    apiService({
      method: "GET",
      url: `${TRAVELER_ENDPOINT}${replaceStringParam(
        FNC1A_Get_Traveler_Base,
        travelerId,
      )}`,
    }),
  getTravelerDetails: (id: string) =>
    apiService({
      method: "GET",
      url: `${TRAVELER_ENDPOINT}${replaceStringParam(
        FNC1A_Get_Traveler_Details,
        id,
      )}`,
    }),
  grantAuthorityPattern: (data: SubAdminSettingFormDataPostType) =>
    apiService({
      data,
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${replaceStringParam(
        FNC1A_Grant_Authority_Pattern,
        data.patternId,
      )}`,
    }),
  requestBrowseTravelers: (data?: TravelersRequestDeleteReqType) =>
    apiService({
      data,
      method: "POST",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Request_Browse_Travelers}`,
    }),
  requestEnable: (listTravelers: Key[]) =>
    apiService({
      data: listTravelers,
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Request_Enable}`,
    }),
  requestToDisableTraveler: (id: string) =>
    apiService({
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${replaceStringParam(
        FNC1A_Request_To_Disable_Traveler,
        id,
      )}`,
    }),
  requestToDisableTravelers: (data: string[]) =>
    apiService({
      data,
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Request_To_Disable_Travelers}`,
    }),
  updateTimezone: (data: TimezoneResType) =>
    apiService({
      data,
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Update_Timezone}`,
    }),
  updateTraveler: (travelerId: string, data: TravelersEditResType) =>
    apiService({
      data,
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${replaceStringParam(
        FNC1A_Update_Traveler,
        travelerId,
      )}`,
    }),
}
