/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { Form } from "antd"
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { FullNameType } from "../../../../../../../_webui/layout/components/Typography/FullName/models"
import { useModal } from "../../../../../../../utils/hook/useModal"
import {
  TravelersEditProps,
  TravelersEditResType,
} from "../../../../models/edit"
import { useRowSelection } from "../useRowSelection"
import { useVariablesUtils } from "../useVariablesUtils"

export const useVariablesForm = () => {
  const [form] = Form.useForm()
  const { push } = useHistory()
  const { state } = useLocation<TravelersEditProps>()
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenPopup,
    onCancel: onCancelPopup,
    onOpen: onOpenPopup,
  } = useModal()
  const [dispatchType, setDispatchType] = useState<OptionItemType[]>([])
  const [listKey1IsAccompanyingFamily, setListKey1IsAccompanyingFamily] =
    useState<string[]>([])
  const [listKey1IsValue5, setListKey1IsValue5] = useState<string[]>([])
  const [fullName, setFullName] = useState<FullNameType>()
  const [submitValues, setSubmitValues] = useState<TravelersEditResType>()
  const [isAccompanyingFamily, setIsAccompanyingFamily] = useState(false)
  const [isChangeUser, setIsChangeUser] = useState(false)
  const [isCopyEmailAddress, setIsCopyEmailAddress] = useState(false)
  const [isDifferentInforCheck, setIsDifferentInforCheck] = useState(false)
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false)
  const [isHiddenTrainingDate, setIsHiddenTrainingDate] = useState(false)
  const [isInsuranceRequired, setIsInsuranceRequired] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isOtherCity, setIsOtherCity] = useState(false)
  const [isOverseasResident, setIsOverseasResident] = useState(true)
  const [isRequiredUrgentContact, setIsRequiredUrgentContact] = useState(false)
  const [countryCode, setCountryCode] = useState("")
  const [dispatchTypeDetailId, setDispatchTypeDetailId] = useState("")
  const [othersStaffNumber, setOthersStaffNumber] = useState("")
  const [userId, setUserId] = useState("")
  const { allCountries, cities, departments, prefectures, relationships } =
    useVariablesUtils(countryCode)
  const {
    accompanies,
    accompaniesTemp,
    addItems,
    indexTabActive,
    isDisabledBtnDelete,
    onTabClick,
    removeItems,
    rowSelection,
    setAccompanies,
    setAccompaniesTemp,
    setTotal,
  } = useRowSelection()

  return {
    accompanies,
    accompaniesTemp,
    addItems,
    allCountries,
    cities,
    departments,
    dispatchType,
    dispatchTypeDetailId,
    form,
    fullName,
    indexTabActive,
    isAccompanyingFamily,
    isChangeUser,
    isCopyEmailAddress,
    isDifferentInforCheck,
    isDisabledBtnDelete,
    isDisabledSubmit,
    isHiddenTrainingDate,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenPopup,
    isOtherCity,
    isOverseasResident,
    isRequiredUrgentContact,
    listKey1IsAccompanyingFamily,
    listKey1IsValue5,
    onCancel,
    onCancelPopup,
    onOpen,
    onOpenPopup,
    onTabClick,
    othersStaffNumber,
    prefectures,
    push,
    relationships,
    removeItems,
    rowSelection,
    setAccompanies,
    setAccompaniesTemp,
    setCountryCode,
    setDispatchType,
    setDispatchTypeDetailId,
    setFullName,
    setIsAccompanyingFamily,
    setIsChangeUser,
    setIsCopyEmailAddress,
    setIsDifferentInforCheck,
    setIsDisabledSubmit,
    setIsHiddenTrainingDate,
    setIsInsuranceRequired,
    setIsLoading,
    setIsLoadingLayout,
    setIsOtherCity,
    setIsOverseasResident,
    setIsRequiredUrgentContact,
    setListKey1IsAccompanyingFamily,
    setListKey1IsValue5,
    setOthersStaffNumber,
    setSubmitValues,
    setTotal,
    setUserId,
    state,
    submitValues,
    userId,
  }
}
