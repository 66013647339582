/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ItemDatePickerRange } from "../../../../../../_webui/layout/components/Form/Item/DatePicker/Range"
import { ItemInput } from "../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../_webui/layout/components/Form/Item/Select"
import { FormSearch } from "../../../../../../_webui/layout/components/Form/Search"
import { FormSearchLeft } from "../../../../../../_webui/layout/components/Form/Search/Left"
import { FormSearchRight } from "../../../../../../_webui/layout/components/Form/Search/Right"
import { SchedulesFormSearchProps } from "./models"
import { Styled } from "./styled"

const { StyledTitle, StyledTitle2 } = Styled
const {
  TEXT_T009,
  TEXT_T191,
  TEXT_T193,
  TEXT_T194,
  TEXT_T195,
  TEXT_T196,
  TEXT_T197,
  TEXT_T217,
  TEXT_T219,
  TEXT_T619,
} = Translate

export const SchedulesFormSearch: FC<SchedulesFormSearchProps> = (props) => {
  const {
    // disabledDateTo,
    countries,
    departments,
    form,
    handleSearch,
    isHiddenBtnResetForm,
    isHiddenBtnSearchBasic,
    isPathnameSchedules,
    isSearchAdvance,
    onBlurBasic,
    onClickReset,
    onKeyDownBasic,
    onOpenSearchAdvance,
  } = props

  return (
    <FormSearch
      form={form}
      heading={TEXT_T191}
      isAdvanced
      isHiddenReset={!isHiddenBtnResetForm}
      isHiddenSubmit={!isHiddenBtnSearchBasic}
      labelAlign="left"
      onClickAdvanced={onOpenSearchAdvance}
      onClickReset={onClickReset}
      onFinish={handleSearch}
      resetHtmlType="button"
    >
      <FormSearchLeft>
        {isPathnameSchedules ? (
          <ItemInput isReadOnly label={TEXT_T219} name="fullName" />
        ) : (
          <ItemInput label={TEXT_T219} maxLength={102} name="fullName" />
        )}
      </FormSearchLeft>

      <FormSearchRight>
        <ItemSelect
          label={TEXT_T193}
          name="countryToTravel"
          selectItem={countries}
        />
      </FormSearchRight>

      <FormSearchLeft>
        <ItemInput label={TEXT_T194} maxLength={200} name="projectName" />
      </FormSearchLeft>

      <FormSearchRight>
        <ItemDatePickerRange
          label={TEXT_T195}
          labelFrom={TEXT_T196}
          labelTo={TEXT_T197}
          name="travelDate"
          nameFrom="departureDateTimeDate"
          nameTo="arrivalDateTimeDate"
          // disabledDateTo={disabledDateTo}
          onBlurFrom={(event) =>
            onBlurBasic(event, TEXT_T196, "departureDateTimeDate")
          }
          onBlurTo={(event) =>
            onBlurBasic(event, TEXT_T197, "arrivalDateTimeDate")
          }
          onKeyDownFrom={(event) =>
            onKeyDownBasic(event, TEXT_T196, "departureDateTimeDate")
          }
          onKeyDownTo={(event) =>
            onKeyDownBasic(event, TEXT_T197, "arrivalDateTimeDate")
          }
        />
      </FormSearchRight>

      {!isPathnameSchedules ? (
        <Fragment>
          <FormSearchLeft>
            <ItemInput
              label={TEXT_T217}
              maxLength={200}
              name="currentLocation"
            />
          </FormSearchLeft>

          <FormSearchRight>
            <ItemSelect
              label={TEXT_T009}
              name="bumonCd"
              selectItem={departments}
            />
            {isSearchAdvance && (
              <StyledTitle2 type="danger" titleRight={TEXT_T619} />
            )}
          </FormSearchRight>
        </Fragment>
      ) : (
        isSearchAdvance && <StyledTitle type="danger" titleRight={TEXT_T619} />
      )}
    </FormSearch>
  )
}
