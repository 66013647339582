import Excel from "exceljs"
import { Translate } from "../../../../../../../../../constant/Translate"

const {
  EXPORT_EP0020, // 案件名    Project name
  EXPORT_EP0056, // 時刻    Time
  EXPORT_EP0065, // 宿泊先名称    Accommodation name
  EXPORT_EP0106, // 渡航目的    Purpose of travel
  EXPORT_EP0133, // 便名/交通手段    Fright No/Transportation
  EXPORT_EP0115, // 日付    Date
  EXPORT_EP0119, // 年月    YYYYMM
} = Translate

export const columnsConfig = (worksheet: Excel.Worksheet) => {
  worksheet.columns = [
    { width: 38 },
    { width: 22 }, // width = 22 * 8 = 176px
    { width: 22 },
    { width: 30 },
    { width: 22 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 38 },
  ]
  worksheet.getCell("A2").value = EXPORT_EP0020
  worksheet.getCell("B2").value = EXPORT_EP0119
  worksheet.getCell("C2").value = EXPORT_EP0115
  worksheet.getCell("D2").value = EXPORT_EP0056
  worksheet.getCell("E2").value = EXPORT_EP0106
  worksheet.getCell("F2").value = EXPORT_EP0133
  worksheet.getCell("G2").value = "FROM"
  worksheet.getCell("H2").value = ""
  worksheet.getCell("I2").value = "TO"
  worksheet.getCell("J2").value = ""
  worksheet.getCell("K2").value = EXPORT_EP0065
}
