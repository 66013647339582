import { FC } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { Form } from "../../../../../../../_webui/layout/components/Form"
import { FilesUploadFormProps } from "../../../../models/files/upload"
import { Styled } from "./styled"
import { requiredField } from "../../../../../../../utils/requiredField"

const { StyledItemInput, StyledItemSelect } = Styled
const { TEXT_T055, TEXT_T065 } = Translate

export const FilesUploadForm: FC<FilesUploadFormProps> = (props) => {
  const {
    allCountries,
    fileTypeList,
    form,
    isCountry,
    isDisabledCountries,
    isStateFileType,
    onChangeCountryCode,
    onChangeFileType,
    requiredCountryCode,
    requiredFileType,
  } = props
  const _requiredFileType = requiredField(requiredFileType)
  const _requiredCountryCode = requiredField(requiredCountryCode)

  return (
    <Form form={form} isCard={false} layout="inline">
      {isStateFileType ? (
        <StyledItemInput isReadOnly label={TEXT_T065} name="fileType" />
      ) : (
        <StyledItemSelect
          label={TEXT_T065}
          name="fileType"
          onChange={onChangeFileType}
          rules={_requiredFileType}
          selectItem={fileTypeList}
        />
      )}
      {isCountry && (
        <StyledItemSelect
          label={TEXT_T055}
          name="countryCode"
          onChange={onChangeCountryCode}
          rules={_requiredCountryCode}
          selectItem={allCountries}
          isDisabled={isDisabledCountries}
        />
      )}
    </Form>
  )
}
