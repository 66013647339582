/* eslint-disable max-lines */
import { useLocation } from "react-router-dom"
import { useVariables as useVariablesAdd } from "../../../add/modules/useVariables"
import { SchedulesEditStateProps } from "../../../../models/edit"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { useState } from "react"

export const useVariables = () => {
  const { state } = useLocation<SchedulesEditStateProps>()

  const breadcrumbItems = getBreadcrumbItems(
    state?.isFromSCR210,
    state?.isFromSCR211,
    state?.schedulesURL,
  )
  const {
    allCountries,
    assignmentValidMsg,
    dataSubmit,
    defaultActiveKey,
    departments,
    detailColumnsFirst,
    detailColumnsSecond,
    detailDataSource,
    detailIndexIdentity,
    detailIndexTabActive,
    detailRowSelection,
    detailSelectedRowKeys,
    detailTabsItems,
    detailValidMsg,
    dispatchType,
    dispatchTypeDetailRes,
    form,
    getFlightInfo,
    getInitialValues,
    getPurposeTravelList,
    goToSchedulesClone,
    handleAddDetailItem,
    handleAddLodgingItem,
    handleAddStayItem,
    handleCheckInsuranceAndUrgent,
    handleCheckRequiredDispatchDate,
    handleCheckTwoWay,
    handleDeleteDetailItem,
    handleDeleteLodgingItem,
    handleDeleteStayItem,
    handleError,
    handleErrorValidate,
    handleSetDetailRequiredFields,
    handleSetLodgingRequiredFields,
    handleSetStayRequiredFields,
    id,
    isAccompanyingFamily,
    isActiveBumon,
    isDifferentInfoChecked,
    isDisabledSubmit,
    isInsuranceAndUrgent,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isOpenRoundTrip,
    isOtherStaffNumber,
    isRequiredDispatchDate,
    isRequiredItemTableByDate,
    isRequiredItemTableByPPTV,
    isRequiredItemTableLodgings,
    isRoleAdmin,
    isSchedulesAdd,
    isSchedulesEdit,
    isSubmitValidate,
    isTwoWay,
    isValidForm,
    isVisibleBtn,
    isVisibleBtnCancel,
    isVisibleBtnConfirm,
    isVisibleBtnCopy,
    isVisibleOtherRemarks,
    listPPTVIsValue3Equal1,
    listPPTVIsValue4Equal2,
    lodgingColumns,
    lodgingDataSource,
    lodgingIndexIdentity,
    lodgingIndexTabActive,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingTabsItems,
    lodgingValidMsg,
    onBlur,
    onCancel,
    onCancelRoundTrip,
    onChangeCollapse,
    onChangeDate,
    onChangeDifferentInfo,
    onChangeTravelPurpose,
    onClickDetailTab,
    onClickLodgingTab,
    onClickStayTab,
    onDispatchTypeDetailChange,
    onFinish,
    onKeyDown,
    onOkRoundTrip,
    onValidateChangeDate,
    onValuesChange,
    pathname,
    purposeTravelList,
    push,
    scheduleDetails,
    scheduleLodgings,
    schedulesData,
    scheduleStays,
    setAssignmentValidMsg,
    setDataScheduleDetails,
    setDataScheduleLodgings,
    setDataScheduleStays,
    setDefaultActiveKey,
    setDefaultCountries,
    setDepartments,
    setDetailValidMsg,
    setDispatchType,
    setDispatchTypeDetailRes,
    setIsAccompanyingFamily,
    setIsActiveBumon,
    setIsDifferentInfoChecked,
    setIsLoading,
    setIsLoadingLayout,
    setIsOtherStaffNumber,
    setIsRequiredItemTableByPPTV,
    setIsRequiredItemTableLodgings,
    setIsTwoWay,
    setLodgingValidMsg,
    setPurposeTravelRes,
    setSchedulesData,
    setTravelerBumonCd,
    setTravelerId,
    setWorkPlaceCountryCode,
    setWorkplaceLocation,
    stayColumns,
    stayDataSource,
    stayIndexIdentity,
    stayIndexTabActive,
    stayRowSelection,
    staySelectedRowKeys,
    stayTabsItems,
    transportationList,
    travelerBumonCd,
    travelerId,
    workplaceLocation,
  } = useVariablesAdd()

  const [isTableLoading, setIsTableLoading] = useState(false)

  return {
    allCountries,
    assignmentValidMsg,
    breadcrumbItems,
    dataSubmit,
    defaultActiveKey,
    departments,
    detailColumnsFirst,
    detailColumnsSecond,
    detailDataSource,
    detailIndexIdentity,
    detailIndexTabActive,
    detailRowSelection,
    detailSelectedRowKeys,
    detailTabsItems,
    detailValidMsg,
    dispatchType,
    dispatchTypeDetailRes,
    form,
    getFlightInfo,
    getInitialValues,
    getPurposeTravelList,
    goToSchedulesClone,
    handleAddDetailItem,
    handleAddLodgingItem,
    handleAddStayItem,
    handleCheckInsuranceAndUrgent,
    handleCheckRequiredDispatchDate,
    handleCheckTwoWay,
    handleDeleteDetailItem,
    handleDeleteLodgingItem,
    handleDeleteStayItem,
    handleError,
    handleErrorValidate,
    handleSetDetailRequiredFields,
    handleSetLodgingRequiredFields,
    handleSetStayRequiredFields,
    id,
    isAccompanyingFamily,
    isActiveBumon,
    isDifferentInfoChecked,
    isDisabledSubmit,
    isInsuranceAndUrgent,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isOpenRoundTrip,
    isOtherStaffNumber,
    isRequiredDispatchDate,
    isRequiredItemTableByDate,
    isRequiredItemTableByPPTV,
    isRequiredItemTableLodgings,
    isRoleAdmin,
    isSchedulesAdd,
    isSchedulesEdit,
    isSubmitValidate,
    isTableLoading,
    isTwoWay,
    isValidForm,
    isVisibleBtn,
    isVisibleBtnCancel,
    isVisibleBtnConfirm,
    isVisibleBtnCopy,
    isVisibleOtherRemarks,
    listPPTVIsValue3Equal1,
    listPPTVIsValue4Equal2,
    lodgingColumns,
    lodgingDataSource,
    lodgingIndexIdentity,
    lodgingIndexTabActive,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingTabsItems,
    lodgingValidMsg,
    onBlur,
    onCancel,
    onCancelRoundTrip,
    onChangeCollapse,
    onChangeDate,
    onChangeDifferentInfo,
    onChangeTravelPurpose,
    onClickDetailTab,
    onClickLodgingTab,
    onClickStayTab,
    onDispatchTypeDetailChange,
    onFinish,
    onKeyDown,
    onOkRoundTrip,
    onValidateChangeDate,
    onValuesChange,
    pathname,
    purposeTravelList,
    push,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    schedulesData,
    setAssignmentValidMsg,
    setDataScheduleDetails,
    setDataScheduleLodgings,
    setDataScheduleStays,
    setDefaultActiveKey,
    setDefaultCountries,
    setDepartments,
    setDetailValidMsg,
    setDispatchType,
    setDispatchTypeDetailRes,
    setIsAccompanyingFamily,
    setIsActiveBumon,
    setIsDifferentInfoChecked,
    setIsLoading,
    setIsLoadingLayout,
    setIsOtherStaffNumber,
    setIsRequiredItemTableByPPTV,
    setIsRequiredItemTableLodgings,
    setIsTableLoading,
    setIsTwoWay,
    setLodgingValidMsg,
    setPurposeTravelRes,
    setSchedulesData,
    setTravelerBumonCd,
    setTravelerId,
    setWorkPlaceCountryCode,
    setWorkplaceLocation,
    state,
    stayColumns,
    stayDataSource,
    stayIndexIdentity,
    stayIndexTabActive,
    stayRowSelection,
    staySelectedRowKeys,
    stayTabsItems,
    transportationList,
    travelerBumonCd,
    travelerId,
    workplaceLocation,
  }
}
