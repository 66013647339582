import { Form } from "antd"
import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonSubmit } from "../../../../../../_webui/layout/components/Button/Submit"
import {
  TravelersEditButtonSubmitProps,
  TravelersEditFormDataType,
} from "../../../models/edit"

const { TEXT_T012, TEXT_T013 } = Translate
export const TravelersEditButtonSubmit: FC<
  TravelersEditButtonSubmitProps<TravelersEditFormDataType>
> = (props) => {
  const {
    form,
    goToTravelersDetailOrAddSuccess,
    isDisabledSubmit,
    isHiddenBtnCancel,
    isHiddenBtnSubmit,
  } = props

  return (
    <Form.Item shouldUpdate className="ant-form-btn-submit">
      {() => (
        <ButtonSubmit
          isReset
          isDisabledSubmit={
            isDisabledSubmit ||
            (form &&
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length)
          }
          isHiddenReset={!isHiddenBtnCancel}
          isHiddenSubmit={!isHiddenBtnSubmit}
          onClickReset={goToTravelersDetailOrAddSuccess}
          resetText={TEXT_T012}
          resetType="ghost"
          submitText={TEXT_T013}
        />
      )}
    </Form.Item>
  )
}
