import { useEffect } from "react"
import { useVariables } from "../useVariables"
import { Routers } from "../../../../../../../constant/Routers"
import {
  SystemsManualDataSourceType,
  UseSystemsManualType,
} from "../../../../models/manual"
import { backToLogin } from "../../../../../../../utils/backToLogin"

const { SCR920 } = Routers

export const useSystemsManual =
  (): UseSystemsManualType<SystemsManualDataSourceType> => {
    const {
      breadcrumbItems,
      columns,
      fileInfoList,
      getFiles,
      handleDownload,
      handlePreviewFile,
      isDisabledBtnDownload,
      isDisabledBtnPreview,
      isLoading,
      isLoadingLayout,
      isNotAccessScreen,
      isOpenPreviewFile,
      isVisibleBtn,
      layoutTitle,
      onCancelPreviewFile,
      onChangePagination,
      onChangeTable,
      perItem,
      push,
      rowSelection,
      type,
    } = useVariables()

    // 「SCR920_ホーム画面」へ遷移する。
    const goToHome = () => {
      push(SCR920)
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (isNotAccessScreen) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else getFiles({ type })
    }, [])

    return {
      breadcrumbItems,
      columns,
      dataSource: fileInfoList,
      goToHome,
      handleDownload,
      handlePreviewFile,
      isDisabledBtnDownload,
      isDisabledBtnPreview,
      isLoading,
      isLoadingLayout,
      isOpenPreviewFile,
      isVisibleBtn,
      layoutTitle,
      onCancelPreviewFile,
      onChangePagination,
      onChangeTable,
      perItem,
      rowSelection,
    }
  }
