const FNC1A_Add_Traveler = "/v1"
const FNC1A_Update_Traveler = "/v1/{0}/details"
const FNC1A_Get_Traveler_Base = "/v1/{0}/base"
const FNC1A_Get_Traveler_Details = "/v1/{0}/details"
const FNC1A_Browse_Travelers = "/v1/browse"
const FNC1A_Request_Browse_Travelers = "/v1/request/browse"
const FNC1A_Count = "/v1/count"
const FNC1A_Export = "/v1/file/export"
const FNC1A_Disable_Traveler = "/v1/{0}/disable"
const FNC1A_Disable_Travelers = "/v1/disable"
const FNC1A_Request_To_Disable_Traveler = "/v1/{0}/request-disable"
const FNC1A_Request_To_Disable_Travelers = "/v1/request-disable"
const FNC1A_Accept_Request_Disable = "/v1/accept-request-disable"
const FNC1A_Cancel_Request_Disable = "/v1/cancel-request-disable"
const FNC1A_Enable = "/v1/enable"
const FNC1A_Request_Enable = "/v1/request-enable"
const FNC1A_Bulk_File_Add = "/v1/bulk/file-add"
const FNC1A_Bulk_File_Update = "/v1/bulk/file-update"
const FNC1A_Bulk_File_Delete = "/v1/bulk/file-delete"
const FNC1A_Bulk_Add = "/v1/bulk"
const FNC1A_Bulk_Update = "/v1/bulk"
const FNC1A_Bulk_Delete = "/v1/bulk"
const FNC1A_Bulk_Request_Delete = "/v1/bulk/request-deletion"
const FNC1A_Bulk_Data = "/v1/bulk/data"
const FNC1A_Bulk_Delete_Data = "/v1/bulk/{0}"
const FNC1A_Bulk_Check = "/v1/bulk/{0}/check"
const FNC1A_Notifications = "/v1/notifications"
const FNC1A_Agreements = "/v1/agreements"
const FNC1A_Accept_Agreements = "/v1/accept-agreements"
const FNC1A_Menus = "/v1/menus"
const FNC1A_Get_Timezone = "/v1/timezone"
const FNC1A_Update_Timezone = "/v1/timezone"
const FNC1A_Change_Language = "/v1/language"
const FNC1A_Grant_Authority_Pattern = "/v1/authorities"
const FNC1A_My_Login = "/v1/user-information"
const FNC1A_Update_Fist_Login = "/v1/first-login"
const FNC1A_Change_Password = "/v1/account/change-password"
const FNC1A_Reset_Password = "/v1/account/reset-password"
const FNC1A_Get_Safeties = "/v1/safeties"

export const TRAVELER_ENDPOINT = {
  FNC1A_Accept_Agreements,
  FNC1A_Accept_Request_Disable,
  FNC1A_Add_Traveler,
  FNC1A_Agreements,
  FNC1A_Browse_Travelers,
  FNC1A_Bulk_Add,
  FNC1A_Bulk_Check,
  FNC1A_Bulk_Data,
  FNC1A_Bulk_Delete,
  FNC1A_Bulk_Delete_Data,
  FNC1A_Bulk_File_Add,
  FNC1A_Bulk_File_Delete,
  FNC1A_Bulk_File_Update,
  FNC1A_Bulk_Request_Delete,
  FNC1A_Bulk_Update,
  FNC1A_Cancel_Request_Disable,
  FNC1A_Change_Language,
  FNC1A_Change_Password,
  FNC1A_Count,
  FNC1A_Disable_Traveler,
  FNC1A_Disable_Travelers,
  FNC1A_Enable,
  FNC1A_Export,
  FNC1A_Get_Safeties,
  FNC1A_Get_Timezone,
  FNC1A_Get_Traveler_Base,
  FNC1A_Get_Traveler_Details,
  FNC1A_Grant_Authority_Pattern,
  FNC1A_Menus,
  FNC1A_My_Login,
  FNC1A_Notifications,
  FNC1A_Request_Browse_Travelers,
  FNC1A_Request_Enable,
  FNC1A_Request_To_Disable_Traveler,
  FNC1A_Request_To_Disable_Travelers,
  FNC1A_Reset_Password,
  FNC1A_Update_Fist_Login,
  FNC1A_Update_Timezone,
  FNC1A_Update_Traveler,
}
