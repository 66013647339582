/* eslint-disable max-lines */
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { replaceString } from "../../../../../../../utils/replaceString"
import {
  TravelersEditFormDataType,
  UseTravelerEditDetailType,
} from "../../../../models/edit"
import { useVariables } from "../useVariables"

const { SCR113 } = Routers
const { TEXT_T067 } = Translate

export const useTravelerEditDetail =
  (): UseTravelerEditDetailType<TravelersEditFormDataType> => {
    const { push } = useHistory()
    const {
      accompanies,
      addItems,
      allCountries,
      breadcrumbItems,
      cities,
      columns,
      departments,
      dispatchType,
      form,
      fullName,
      getTravelerDetails,
      id,
      indexTabActive,
      isAccompanyingFamily,
      isChangeUser,
      isCopyEmailAddress,
      isDifferentInforCheck,
      isDisabledBtnDelete,
      isDisabledSubmit,
      isHiddenBtnCancel,
      isHiddenBtnSubmit,
      isHiddenTrainingDate,
      isInsuranceRequired,
      isLoading,
      isLoadingLayout,
      isNotAccessScreen,
      isOpen,
      isOpenPopup,
      isOtherCity,
      isOverseasResident,
      isPersonUrl,
      isRequiredUrgentContact,
      isRoleAdmin,
      isRoleSubAdmin,
      isRoleUser,
      listKey1IsAccompanyingFamily,
      onBlur,
      onCancel,
      onChangeTrainingType,
      onCopyEmailAddress,
      onDifferentInforCheckChange,
      onDispatchTypeDetailChange,
      onFinish,
      onKeyDown,
      onOk,
      onOkPopup,
      onTabClick,
      onValuesChange,
      onWorkPlaceCityChange,
      onWorkPlaceCountryChange,
      onWorkPlaceLocationChange,
      othersStaffNumber,
      prefectures,
      removeItems,
      rowSelection,
      state,
      tabsItems,
    } = useVariables()

    const goToTravelersDetailOrAddSuccess = () => {
      if (isRoleAdmin || isRoleSubAdmin) {
        if (state?.sourceURL === SCR113) {
          // 「SCR113_渡航者の登録後画面」から遷移してきた場合
          push(replaceString(SCR113, id))
        } else {
          // 「SCR115_渡航者情報の参照画面」から遷移してきた場合
          push(state.sourceURL, { ...state })
        }
      }
      if (isRoleUser) {
        // 「SCR115_渡航者情報の参照画面」に遷移する。
        push(state.sourceURL)
      }
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (isNotAccessScreen) {
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else getTravelerDetails()
    }, [])

    useEffect(() => {
      form.setFieldValue(
        "accompaniesLength",
        `${accompanies.length}${TEXT_T067}`,
      )
    }, [accompanies])

    useEffect(() => {
      if (listKey1IsAccompanyingFamily?.length) onValuesChange()
    }, [listKey1IsAccompanyingFamily])

    return {
      accompanies,
      addItems,
      allCountries,
      breadcrumbItems,
      cities,
      columns,
      departments,
      dispatchType,
      form,
      fullName,
      goToTravelersDetailOrAddSuccess,
      indexTabActive,
      isAccompanyingFamily,
      isChangeUser,
      isCopyEmailAddress,
      isDifferentInforCheck,
      isDisabledBtnDelete,
      isDisabledSubmit,
      isHiddenBtnCancel,
      isHiddenBtnSubmit,
      isHiddenTrainingDate,
      isInsuranceRequired,
      isLoading,
      isLoadingLayout,
      isOpen,
      isOpenPopup,
      isOtherCity,
      isOverseasResident,
      isPersonUrl,
      isRequiredUrgentContact,
      isRoleAdmin,
      isRoleSubAdmin,
      isRoleUser,
      onBlur,
      onCancel,
      onChangeTrainingType,
      onCopyEmailAddress,
      onDifferentInforCheckChange,
      onDispatchTypeDetailChange,
      onFinish,
      onKeyDown,
      onOk,
      onOkPopup,
      onTabClick,
      onValuesChange,
      onWorkPlaceCityChange,
      onWorkPlaceCountryChange,
      onWorkPlaceLocationChange,
      othersStaffNumber,
      prefectures,
      removeItems,
      rowSelection,
      tabsItems,
    }
  }
