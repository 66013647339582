import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Translate } from "../../../../../constant/Translate"
import { SchedulesAddForm } from "../../components/add/Form"
import { useSchedulesAdd } from "./modules/useAdd"

const { TEXT_T271, TEXT_T272 } = Translate

export const SchedulesAdd: FC = () => {
  const {
    breadcrumbItems,
    goToSchedulesClone,
    isLoading,
    isLoadingLayout,
    isVisibleBtnCopy,
    ...rest
  } = useSchedulesAdd()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T271}
    >
      <Card
        heading={TEXT_T271}
        isLoading={isLoading}
        isSearch={isVisibleBtnCopy}
        onClick={goToSchedulesClone}
        searchText={TEXT_T272}
      >
        <SchedulesAddForm {...rest} />
      </Card>
    </Layout>
  )
}
