import { useState } from "react"
import { useHistory } from "react-router-dom"
import { getAllCountries } from "../../../../../../../../utils/apis/getAllCountries"
import { useApi } from "../useApi"
import { useHandle } from "../useHandle"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { FilesType } from "../../../../../../../../utils/downloadFiles/models"
import { Translate } from "../../../../../../../../constant/Translate"
import { OptionItemType } from "../../../../../../../../_webui/layout/components/Select/models"
import { getGeneralMasters } from "../../../../../../../../utils/apis/getGeneralMasters"
import { GeneralCode } from "../../../../../../../../constant/GeneralCode"

const { TEXT_T509 } = Translate
const { TYPE } = GeneralCode

export const useVariables = () => {
  const { push } = useHistory()
  const {
    isNotAccessScreen,
    isVisibleBtnCancel,
    isVisibleBtnDownload,
    isVisibleBtnReference,
  } = useVariablesAuthority()
  // APIを呼び出し、APIからの応答結果を「戻り国情報」変数に設定する。
  const { allCountries } = getAllCountries()
  const { columns } = useTableConfig()
  const [fileTypeList, setFileTypeList] = useState<OptionItemType[]>([])
  //  APIを呼び出し、種類一覧を取得し
  const { generalMasters: typeInfo } = getGeneralMasters(TYPE)

  const {
    fileInfoList,
    form,
    getFiles,
    handleGetDataSource,
    initDateTime,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isLoading,
    isLoadingLayout,
    onChangePagination,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
  } = useApi()
  const _fileInfoList: FilesType[] = fileInfoList.map((item) => ({
    fileName: item.fileName,
    filePath: item.filePath,
    fileTypeName: item.fileTypeName,
    id: item.uploadId,
  }))
  const {
    handleDownload,
    handlePreviewFile,
    isLoading: isLoadingExport,
    isOpenPreviewFile,
    onCancelPreviewFile,
  } = useHandle({
    fileInfoList: _fileInfoList,
    fileName: TEXT_T509,
    initDateTime,
    selectedRowKeys,
  })

  return {
    allCountries,
    columns,
    fileInfoList,
    fileTypeList,
    form,
    getFiles,
    handleDownload,
    handleGetDataSource,
    handlePreviewFile,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isLoading: isLoading || isLoadingExport,
    isLoadingLayout,
    isNotAccessScreen,
    isOpenPreviewFile,
    isVisibleBtnCancel,
    isVisibleBtnDownload,
    isVisibleBtnReference,
    onCancelPreviewFile,
    onChangePagination,
    onChangeTable,
    perItem,
    push,
    rowSelection,
    setFileTypeList,
    typeInfo,
  }
}
