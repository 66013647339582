import { concat } from "../../../../../../../../utils/concat"
import { getFullName } from "../../../../../../../../utils/getFullName"
import { TravelersListDataType } from "../../../../../models/list"
import { TravelersListDataSourceType } from "../../../../../models/list/data"

const convertOtherValue = (value?: string) => (value ? value : "          ")

export const convertDataSource = (
  data: TravelersListDataType[],
): TravelersListDataSourceType[] =>
  data.map((item) => ({
    delete: concat(item.detail?.delete),
    dispatchTypeDetailName: item.base.dispatchTypeDetailName,
    fullNameKanji: getFullName(
      item.base.kanjiSurname,
      item.base.kanjiName,
      true,
    ),
    fullNameRomaji: getFullName(item.base.romajiSurname, item.base.romajiName),
    others: `${convertOtherValue(
      item.detail?.othersStaffNumber,
    )}/${convertOtherValue(
      item.detail?.othersDispatchNumber,
    )}/${convertOtherValue(item.detail?.othersMemberNumber)}`,
    placeAndWorkLocation:
      concat(item.detail?.workPlace) || concat(item.detail?.workPlaceCityName),
    systemIdentifier: item.detail?.systemIdentifier,
    travelerId: item.base.travelerId,
    userId: item.base.userId,
    workPlace: concat(item.detail?.workPlace),
    workPlaceCityName: concat(item.detail?.workPlaceCityName),
  }))
