import {
  TravelersEditAccompaniesType,
  TravelersEditColumnType,
} from "../../../../../models/edit"

export const getDataAccompanies = (
  data: TravelersEditAccompaniesType[],
): TravelersEditColumnType[] =>
  data.map((item, index) => ({
    familyId: item.familyId,
    familyRelationship: item.familyRelationship,
    familyRomajiName: item.familyRomajiName,
    familyRomajiSurname: item.familyRomajiSurname,
    index: index,
  }))
