/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { UseParamsType } from "../../../../../../../utils/useParams"
import { SchedulesAddStateProps } from "../../../../models/add"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"
import { getAllCountries } from "../../../../../../../utils/apis/getAllCountries"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { useHandle } from "../useHandle"

const { TPTT } = GeneralCode

export const useVariables = () => {
  const { pathname, state } = useLocation<SchedulesAddStateProps>()
  const { push } = useHistory()
  const { id } = useParams<UseParamsType>()
  const {
    isFromSCR212,
    isNotAccessScreen,
    isRoleAdmin,
    isSchedulesAdd,
    isSchedulesEdit,
    isVisibleBtn,
    isVisibleBtnCancel,
    isVisibleBtnConfirm,
    isVisibleBtnCopy,
    isVisibleOtherRemarks,
  } = useVariablesAuthority(pathname, id)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)

  // APIを呼び出し、交通手段一覧情報を取得し
  const { generalMasters: transportationList } = getGeneralMasters(TPTT)

  // APIを呼び出し、国一覧情報を取得し
  const { allCountries } = getAllCountries()

  const {
    assignmentValidMsg,
    dataSubmit,
    defaultActiveKey,
    departments,
    detailColumnsFirst,
    detailColumnsSecond,
    detailDataSource,
    detailIndexIdentity,
    detailIndexTabActive,
    detailRowSelection,
    detailSelectedRowKeys,
    detailTabsItems,
    detailValidMsg,
    dispatchType,
    dispatchTypeDetailRes,
    form,
    getFlightInfo,
    getInitialValues,
    getPurposeTravelList,
    goToSchedulesClone,
    handleAddDetailItem,
    handleAddLodgingItem,
    handleAddStayItem,
    handleCheckRequiredDispatchDate,
    handleCheckTwoWay,
    handleDeleteDetailItem,
    handleDeleteLodgingItem,
    handleDeleteStayItem,
    handleError,
    handleErrorValidate,
    handleSetDetailRequiredFields,
    handleSetLodgingRequiredFields,
    handleSetStayRequiredFields,
    handleCheckInsuranceAndUrgent,
    isAccompanyingFamily,
    isActiveBumon,
    isDifferentInfoChecked,
    isDisabledSubmit,
    isInsuranceAndUrgent,
    isInsuranceRequired,
    isOpen,
    isOpenRoundTrip,
    isOtherStaffNumber,
    isRequiredDispatchDate,
    isRequiredItemTableByDate,
    isRequiredItemTableByPPTV,
    isRequiredItemTableLodgings,
    isSubmitValidate,
    isTwoWay,
    listPPTVIsValue3Equal1,
    listPPTVIsValue4Equal2,
    lodgingColumns,
    lodgingDataSource,
    lodgingIndexIdentity,
    lodgingIndexTabActive,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingTabsItems,
    lodgingValidMsg,
    onBlur,
    onCancel,
    onCancelRoundTrip,
    onChangeCollapse,
    onChangeDate,
    onChangeDifferentInfo,
    onChangeTravelPurpose,
    onClickDetailTab,
    onClickLodgingTab,
    onClickStayTab,
    onDispatchTypeDetailChange,
    onFinish,
    onKeyDown,
    onOkRoundTrip,
    onValidateChangeDate,
    onValuesChange,
    purposeTravelList,
    scheduleDetails,
    scheduleLodgings,
    schedulesData,
    scheduleStays,
    setAssignmentValidMsg,
    setDataScheduleDetails,
    setDataScheduleLodgings,
    setDataScheduleStays,
    setDefaultActiveKey,
    setDefaultCountries,
    setDepartments,
    setDetailValidMsg,
    setDispatchType,
    setDispatchTypeDetailRes,
    setIsAccompanyingFamily,
    setIsActiveBumon,
    setIsDifferentInfoChecked,
    setIsOtherStaffNumber,
    setIsRequiredItemTableByPPTV,
    setIsRequiredItemTableLodgings,
    setIsTwoWay,
    setLodgingValidMsg,
    setPurposeTravelRes,
    setSchedulesData,
    setTravelerBumonCd,
    setTravelerId,
    setWorkPlaceCountryCode,
    setWorkplaceLocation,
    stayColumns,
    stayDataSource,
    stayIndexIdentity,
    stayIndexTabActive,
    stayRowSelection,
    staySelectedRowKeys,
    stayTabsItems,
    travelerBumonCd,
    travelerId,
    workplaceLocation,
  } = useHandle(isVisibleBtn)

  const breadcrumbItems = getBreadcrumbItems(
    id,
    !!state?.isFromSCR114UserSearch,
    pathname,
  )

  const isValidForm =
    !!detailDataSource.length &&
    !!lodgingDataSource.length &&
    !!stayDataSource.length

  return {
    allCountries,
    assignmentValidMsg,
    breadcrumbItems,
    dataSubmit,
    defaultActiveKey,
    departments,
    detailColumnsFirst,
    detailColumnsSecond,
    detailDataSource,
    detailIndexIdentity,
    detailIndexTabActive,
    detailRowSelection,
    detailSelectedRowKeys,
    detailTabsItems,
    detailValidMsg,
    dispatchType,
    dispatchTypeDetailRes,
    form,
    getFlightInfo,
    getInitialValues,
    getPurposeTravelList,
    goToSchedulesClone,
    handleAddDetailItem,
    handleAddLodgingItem,
    handleAddStayItem,
    handleCheckInsuranceAndUrgent,
    handleCheckRequiredDispatchDate,
    handleCheckTwoWay,
    handleDeleteDetailItem,
    handleDeleteLodgingItem,
    handleDeleteStayItem,
    handleError,
    handleErrorValidate,
    handleSetDetailRequiredFields,
    handleSetLodgingRequiredFields,
    handleSetStayRequiredFields,
    id,
    isAccompanyingFamily,
    isActiveBumon,
    isDifferentInfoChecked,
    isDisabledSubmit,
    isFromSCR212,
    isInsuranceAndUrgent,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isOpenRoundTrip,
    isOtherStaffNumber,
    isRequiredDispatchDate,
    isRequiredItemTableByDate,
    isRequiredItemTableByPPTV,
    isRequiredItemTableLodgings,
    isRoleAdmin,
    isSchedulesAdd,
    isSchedulesEdit,
    isSubmitValidate,
    isTwoWay,
    isValidForm,
    isVisibleBtn,
    isVisibleBtnCancel,
    isVisibleBtnConfirm,
    isVisibleBtnCopy,
    isVisibleOtherRemarks,
    listPPTVIsValue3Equal1,
    listPPTVIsValue4Equal2,
    lodgingColumns,
    lodgingDataSource,
    lodgingIndexIdentity,
    lodgingIndexTabActive,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingTabsItems,
    lodgingValidMsg,
    onBlur,
    onCancel,
    onCancelRoundTrip,
    onChangeCollapse,
    onChangeDate,
    onChangeDifferentInfo,
    onChangeTravelPurpose,
    onClickDetailTab,
    onClickLodgingTab,
    onClickStayTab,
    onDispatchTypeDetailChange,
    onFinish,
    onKeyDown,
    onOkRoundTrip,
    onValidateChangeDate,
    onValuesChange,
    pathname,
    purposeTravelList,
    push,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    schedulesData,
    setAssignmentValidMsg,
    setDataScheduleDetails,
    setDataScheduleLodgings,
    setDataScheduleStays,
    setDefaultActiveKey,
    setDefaultCountries,
    setDepartments,
    setDetailValidMsg,
    setDispatchType,
    setDispatchTypeDetailRes,
    setIsAccompanyingFamily,
    setIsActiveBumon,
    setIsDifferentInfoChecked,
    setIsLoading,
    setIsLoadingLayout,
    setIsOtherStaffNumber,
    setIsRequiredItemTableByPPTV,
    setIsRequiredItemTableLodgings,
    setIsTwoWay,
    setLodgingValidMsg,
    setPurposeTravelRes,
    setSchedulesData,
    setTravelerBumonCd,
    setTravelerId,
    setWorkPlaceCountryCode,
    setWorkplaceLocation,
    state,
    stayColumns,
    stayDataSource,
    stayIndexIdentity,
    stayIndexTabActive,
    stayRowSelection,
    staySelectedRowKeys,
    stayTabsItems,
    transportationList,
    travelerBumonCd,
    travelerId,
    workplaceLocation,
  }
}
