/* eslint-disable max-lines */
const CODE_MESSAGE_C0001 = "MESSAGE.C0001"
const CODE_MESSAGE_C0002 = "MESSAGE.C0002"
const CODE_MESSAGE_C0003 = "MESSAGE.C0003"
const CODE_MESSAGE_C0004 = "MESSAGE.C0004"
const CODE_MESSAGE_C0005 = "MESSAGE.C0005"
const CODE_MESSAGE_C0006 = "MESSAGE.C0006"
const CODE_MESSAGE_C0007 = "MESSAGE.C0007"
const CODE_MESSAGE_C0008 = "MESSAGE.C0008"
const CODE_MESSAGE_C0009 = "MESSAGE.C0009"
const CODE_MESSAGE_C0010 = "MESSAGE.C0010"
const CODE_MESSAGE_C0011 = "MESSAGE.C0011"
const CODE_MESSAGE_C0012 = "MESSAGE.C0012"
const CODE_MESSAGE_C0013 = "MESSAGE.C0013"
const CODE_MESSAGE_C0014 = "MESSAGE.C0014"
const CODE_MESSAGE_C0015 = "MESSAGE.C0015"
const CODE_MESSAGE_C0016 = "MESSAGE.C0016"
const CODE_MESSAGE_C0017 = "MESSAGE.C0017"
const CODE_MESSAGE_C0018 = "MESSAGE.C0018"
const CODE_MESSAGE_C0019 = "MESSAGE.C0019"
const CODE_MESSAGE_C0020 = "MESSAGE.C0020"
const CODE_MESSAGE_C0021 = "MESSAGE.C0021"
const CODE_MESSAGE_C0022 = "MESSAGE.C0022"
const CODE_MESSAGE_C0023 = "MESSAGE.C0023"
const CODE_MESSAGE_C0024 = "MESSAGE.C0024"
const CODE_MESSAGE_C0025 = "MESSAGE.C0025"
const CODE_MESSAGE_C0026 = "MESSAGE.C0026"
const CODE_MESSAGE_C0027 = "MESSAGE.C0027"
const CODE_MESSAGE_C0028 = "MESSAGE.C0028"
const CODE_MESSAGE_C0029 = "MESSAGE.C0029"
const CODE_MESSAGE_C0030 = "MESSAGE.C0030"
const CODE_MESSAGE_C0031 = "MESSAGE.C0031"
const CODE_MESSAGE_C0032 = "MESSAGE.C0032"
const CODE_MESSAGE_C0033 = "MESSAGE.C0033"
const CODE_MESSAGE_C0034 = "MESSAGE.C0034"
const CODE_MESSAGE_C0035 = "MESSAGE.C0035"
const CODE_MESSAGE_C0036 = "MESSAGE.C0036"
const CODE_MESSAGE_C0037 = "MESSAGE.C0037"
const CODE_MESSAGE_C0038 = "MESSAGE.C0038"
const CODE_MESSAGE_C0039 = "MESSAGE.C0039"
const CODE_MESSAGE_C0040 = "MESSAGE.C0040"
const CODE_MESSAGE_C0041 = "MESSAGE.C0041"
const CODE_MESSAGE_C0042 = "MESSAGE.C0042"
const CODE_MESSAGE_C0043 = "MESSAGE.C0043"
const CODE_MESSAGE_C0044 = "MESSAGE.C0044"
const CODE_MESSAGE_C0045 = "MESSAGE.C0045"
const CODE_MESSAGE_C0046 = "MESSAGE.C0046"
const CODE_MESSAGE_C0047 = "MESSAGE.C0047"
const CODE_MESSAGE_C0048 = "MESSAGE.C0048"
const CODE_MESSAGE_C0049 = "MESSAGE.C0049"
const CODE_MESSAGE_C0050 = "MESSAGE.C0050"
const CODE_MESSAGE_C0051 = "MESSAGE.C0051"
const CODE_MESSAGE_C0052 = "MESSAGE.C0052"
const CODE_MESSAGE_C0053 = "MESSAGE.C0053"

export const I18nCodeMessageConfirm = {
  CODE_MESSAGE_C0001,
  CODE_MESSAGE_C0002,
  CODE_MESSAGE_C0003,
  CODE_MESSAGE_C0004,
  CODE_MESSAGE_C0005,
  CODE_MESSAGE_C0006,
  CODE_MESSAGE_C0007,
  CODE_MESSAGE_C0008,
  CODE_MESSAGE_C0009,
  CODE_MESSAGE_C0010,
  CODE_MESSAGE_C0011,
  CODE_MESSAGE_C0012,
  CODE_MESSAGE_C0013,
  CODE_MESSAGE_C0014,
  CODE_MESSAGE_C0015,
  CODE_MESSAGE_C0016,
  CODE_MESSAGE_C0017,
  CODE_MESSAGE_C0018,
  CODE_MESSAGE_C0019,
  CODE_MESSAGE_C0020,
  CODE_MESSAGE_C0021,
  CODE_MESSAGE_C0022,
  CODE_MESSAGE_C0023,
  CODE_MESSAGE_C0024,
  CODE_MESSAGE_C0025,
  CODE_MESSAGE_C0026,
  CODE_MESSAGE_C0027,
  CODE_MESSAGE_C0028,
  CODE_MESSAGE_C0029,
  CODE_MESSAGE_C0030,
  CODE_MESSAGE_C0031,
  CODE_MESSAGE_C0032,
  CODE_MESSAGE_C0033,
  CODE_MESSAGE_C0034,
  CODE_MESSAGE_C0035,
  CODE_MESSAGE_C0036,
  CODE_MESSAGE_C0037,
  CODE_MESSAGE_C0038,
  CODE_MESSAGE_C0039,
  CODE_MESSAGE_C0040,
  CODE_MESSAGE_C0041,
  CODE_MESSAGE_C0042,
  CODE_MESSAGE_C0043,
  CODE_MESSAGE_C0044,
  CODE_MESSAGE_C0045,
  CODE_MESSAGE_C0046,
  CODE_MESSAGE_C0047,
  CODE_MESSAGE_C0048,
  CODE_MESSAGE_C0049,
  CODE_MESSAGE_C0050,
  CODE_MESSAGE_C0051,
  CODE_MESSAGE_C0052,
  CODE_MESSAGE_C0053,
}
