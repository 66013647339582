import { FormInstance } from "antd"
import { getTextByKey } from "../../../../../../../../utils/getTextByKey"
import { getValueComboboxTable } from "../../../../../../../../utils/getValueComboboxTable"
import { OptionItemType } from "../../../../../../../../_webui/layout/components/Select/models"
import { AnyValue } from "../../../../../../../../_webui/layout/models"
import {
  TravelersEditAccompaniesType,
  TravelersEditColumnType,
} from "../../../../../models/edit"

export const convertDataAccompanies = (
  form: FormInstance<AnyValue>,
  accompanies: TravelersEditColumnType[],
  accompaniesTemp: TravelersEditColumnType[],
  relationships?: OptionItemType[],
): TravelersEditAccompaniesType[] => {
  const _accompanies: TravelersEditAccompaniesType[] = []

  accompanies.map((item) => {
    const familyRelationship = form.getFieldValue(
      `familyRelationship_${item.index}`,
    )

    const familyRelationshipName = getTextByKey(
      familyRelationship,
      relationships,
    )

    _accompanies.push({
      familyActionType: item.familyId?.includes("tempId") ? "Add" : "Update",
      familyId: item.familyId,
      familyRelationship: getValueComboboxTable(
        familyRelationship,
        familyRelationshipName,
        item.index,
      ),
      familyRomajiName: form.getFieldValue(`familyRomajiName_${item.index}`),
      familyRomajiSurname: form.getFieldValue(
        `familyRomajiSurName_${item.index}`,
      ),
    })
  })
  const accompaniesDeleteData = accompaniesTemp.filter(
    (item) =>
      !_accompanies.map((item) => item.familyId).includes(item.familyId),
  )

  accompaniesDeleteData.forEach((item) => {
    _accompanies.push({ ...item, familyActionType: "Delete" })
  })

  return _accompanies
}
