import { FC, Fragment } from "react"
import { CardHeadingProps } from "./models"
import { CardSearch } from "../Search"
import { getStyle } from "../../../../../utils/getStyle"
import { Regex } from "../../../../../constant/Regex"
import { CardHeadingIcon } from "./Icon"
import { Styled } from "./styled"

const { REGEX_IS_DESKTOP } = Regex
const { StyledCardHeadingText, StyledDivider } = Styled

export const CardHeading: FC<CardHeadingProps> = (props) => {
  const {
    childrenCustom,
    heading,
    isBackground = true,
    isCenter,
    isHeading = true,
    isSearch,
    isSearchIcon,
    isSmall,
    onClick,
    searchIcon,
    searchText,
    type,
  } = props
  const styleIsCenter = getStyle(isCenter, " card-heading-center")
  const styleIsSearch = getStyle(isSearch, " card-heading-search")
  const styleHeading = `card-heading${styleIsCenter}${styleIsSearch}`

  return isHeading ? (
    <Fragment>
      <div className={styleHeading}>
        <div className="card-heading-left">
          <StyledCardHeadingText
            aria-label={heading}
            className="card-heading-text"
            tabIndex={0}
          >
            {heading}
            <CardHeadingIcon isBackground={isBackground} isCenter={isCenter} />
          </StyledCardHeadingText>
          {childrenCustom}
        </div>
        {!REGEX_IS_DESKTOP && (
          <StyledDivider className="card-heading-divider" />
        )}
        <CardSearch
          isSearch={isSearch}
          isSearchIcon={isSearchIcon}
          isSmall={isSmall}
          searchIcon={searchIcon}
          onClick={onClick}
          searchText={searchText}
          type={type}
        />
      </div>
      {REGEX_IS_DESKTOP && <StyledDivider className="card-heading-divider" />}
    </Fragment>
  ) : null
}
