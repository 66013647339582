import { Translate } from "../../../../../../../../../../../constant/Translate"

const {
  EXPORT_EP0054, // 氏名（日）    Full name(Japanese)
  EXPORT_EP0052, // 氏名（英）    Full name(English)
  EXPORT_EP0055, // 氏名（日）(戸籍表記)    Full name(Japanese)(described in passport)
  EXPORT_EP0053, // 氏名（英）（パスポート表記）    Full name(English)(As shown on passport)
  EXPORT_EP0157, // 人材種別    Dispatch type
  EXPORT_EP0158, // 人材種別（詳細）    Dispatch type (Detail)
  EXPORT_EP0184, // 所属先名    Group name
  EXPORT_EP0103, // 電話番号    Phone number
  EXPORT_EP0015, // メールアドレス    Email
  EXPORT_EP0049, // 国名・任地・勤務地    Country name/place of employment/place of work
  EXPORT_EP0043, // 現在地    Current location
  EXPORT_EP0106, // 渡航目的    Purpose of travel
  EXPORT_EP0020, // 案件名    Project name
  EXPORT_EP0104, // 渡航期間    Travel period
  EXPORT_EP0035, // 拠点担当者名    Name of person in charge of overseas office
  EXPORT_EP0128, // 備考1   Remarks 1
} = Translate

export const heading = `${EXPORT_EP0054},${EXPORT_EP0052},${EXPORT_EP0055},${EXPORT_EP0053},${EXPORT_EP0157},${EXPORT_EP0158},${EXPORT_EP0184},${EXPORT_EP0103},${EXPORT_EP0015},${EXPORT_EP0049},${EXPORT_EP0043},${EXPORT_EP0106},${EXPORT_EP0020},${EXPORT_EP0104},${EXPORT_EP0035},${EXPORT_EP0128}`
