import { Form as FormAntd } from "antd"
import { FC } from "react"
import { ButtonSubmit } from "../../../../../../_webui/layout/components/Button/Submit"
import { Divider } from "../../../../../../_webui/layout/components/Divider"
import { Form } from "../../../../../../_webui/layout/components/Form"
import { FormProvider } from "../../../../../../_webui/layout/components/Form/FormProvider"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { Translate } from "../../../../../../constant/Translate"
import { SchedulesAddCollapse } from "./ContentCollapse"
import { SchedulesAddFormProps } from "./models"

const {
  MESSAGE_C0020,
  MESSAGE_C0021,
  MESSAGE_C0053,
  TEXT_T012,
  TEXT_T013,
  TEXT_T229,
} = Translate

export const SchedulesAddForm: FC<SchedulesAddFormProps> = (props) => {
  const {
    form,
    goBack,
    isDisabledSubmit,
    isOpen,
    isOpenRoundTrip,
    isSchedulesAdd,
    isSchedulesEdit,
    isTwoWay,
    isValidForm,
    isVisibleBtnCancel,
    isVisibleBtnConfirm,
    onCancel,
    onCancelRoundTrip,
    onFinish,
    onOk,
    onOkRoundTrip,
    onValuesChange,
    ...rest
  } = props
  return (
    <FormProvider>
      <Form
        form={form}
        isCard={false}
        isHeading={false}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <SchedulesAddCollapse isTwoWay={isTwoWay} {...rest} />

        <Divider />

        <FormAntd.Item shouldUpdate className="ant-form-btn-submit">
          {() => (
            <ButtonSubmit
              isDisabledSubmit={
                isTwoWay ||
                !isValidForm ||
                isDisabledSubmit ||
                (form &&
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length)
              }
              isHiddenReset={!isVisibleBtnCancel}
              isHiddenSubmit={!isVisibleBtnConfirm}
              isOrder
              isReset
              onClickReset={goBack}
              resetText={TEXT_T012}
              submitText={TEXT_T013}
            />
          )}
        </FormAntd.Item>

        <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
          {isSchedulesAdd
            ? MESSAGE_C0020
            : isSchedulesEdit
            ? MESSAGE_C0021
            : ""}
        </ModalConfirm>
        <ModalConfirm
          cancelCustomText={TEXT_T229}
          cancelText=""
          isOpen={isOpenRoundTrip}
          onOk={onOkRoundTrip}
          onCancelCustom={onCancelRoundTrip}
        >
          {MESSAGE_C0053}
        </ModalConfirm>
      </Form>
    </FormProvider>
  )
}
