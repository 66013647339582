import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { OptionItemType } from "../../../_webui/layout/components/Select/models"
import { GeneralCode } from "../../../constant/GeneralCode"
import { Routers } from "../../../constant/Routers"
import { AxiosErrorType } from "../../../infrastructure/axiosError/models"
import { countriesApi } from "../../../infrastructure/handlers/countries"
import { AllCountriesResType } from "../../../infrastructure/handlers/countries/models"
import { pushStateError } from "../../pushStateError"
import { AllCountriesType } from "./models"

const { SCR973 } = Routers
const { JAPAN_COUNTRY_CODE } = GeneralCode

export const getAllCountries = (isFilterJapan?: boolean): AllCountriesType => {
  const { push } = useHistory()
  const [allCountries, setAllCountries] = useState<OptionItemType[]>([])

  useEffect(() => {
    countriesApi
      .getAllCountries()
      .then((res: AllCountriesResType[]) => {
        const optionItem: OptionItemType[] = res.map((item) => ({
          text: item.countryName,
          value: item.countryCode,
        }))

        if (isFilterJapan) {
          const _optionItem = optionItem.filter(
            (item) => item.value !== JAPAN_COUNTRY_CODE,
          )
          setAllCountries(_optionItem)
        } else setAllCountries(optionItem)
      })
      .catch((error: AxiosErrorType) => {
        const state = pushStateError(error)
        push({ pathname: SCR973, state })
      })
  }, [])

  return {
    allCountries,
  }
}
