/* eslint-disable max-lines */
import { FC } from "react"
import { Form } from "../../../../../_webui/layout/components/Form"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { ModalConfirm } from "../../../../../_webui/layout/components/Modal/Confirm"
import { FullName } from "../../../../../_webui/layout/components/Typography/FullName"
import { Translate } from "../../../../../constant/Translate"
import { TravelersEditButtonSubmit } from "../../components/edit/ButtonSubmit"
import { TravelersEditCollapse } from "../../components/edit/Collapse"
import { useTravelerEditDetail } from "./modules/useEdit"
import { Popup } from "../../../../../_webui/layout/components/Modal/Popup"

const { MESSAGE_C0005, MESSAGE_W0014, TEXT_T084 } = Translate

export const TravelersEdit: FC = () => {
  const {
    accompanies,
    addItems,
    allCountries,
    breadcrumbItems,
    cities,
    columns,
    departments,
    dispatchType,
    form,
    fullName,
    goToTravelersDetailOrAddSuccess,
    indexTabActive,
    isAccompanyingFamily,
    isChangeUser,
    isCopyEmailAddress,
    isDifferentInforCheck,
    isDisabledBtnDelete,
    isDisabledSubmit,
    isHiddenBtnCancel,
    isHiddenBtnSubmit,
    isHiddenTrainingDate,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenPopup,
    isOtherCity,
    isOverseasResident,
    isPersonUrl,
    isRequiredUrgentContact,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    onBlur,
    onCancel,
    onChangeTrainingType,
    onCopyEmailAddress,
    onDifferentInforCheckChange,
    onDispatchTypeDetailChange,
    onFinish,
    onKeyDown,
    onOk,
    onOkPopup,
    onTabClick,
    onValuesChange,
    onWorkPlaceCityChange,
    onWorkPlaceCountryChange,
    onWorkPlaceLocationChange,
    othersStaffNumber,
    prefectures,
    removeItems,
    rowSelection,
    tabsItems,
  } = useTravelerEditDetail()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T084}
    >
      <Form
        form={form}
        heading={TEXT_T084}
        isLoading={isLoading}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <FullName fullName={fullName} />

        <TravelersEditCollapse
          accompanies={accompanies}
          addItems={addItems}
          allCountries={allCountries}
          cities={cities}
          columns={columns}
          departments={departments}
          dispatchType={dispatchType}
          indexTabActive={indexTabActive}
          isAccompanyingFamily={isAccompanyingFamily}
          isChangeUser={isChangeUser}
          isCopyEmailAddress={isCopyEmailAddress}
          isDifferentInforCheck={isDifferentInforCheck}
          isDisabledBtnDelete={isDisabledBtnDelete}
          isHiddenTrainingDate={isHiddenTrainingDate}
          isInsuranceRequired={isInsuranceRequired}
          isOtherCity={isOtherCity}
          isOverseasResident={isOverseasResident}
          isRequiredUrgentContact={isRequiredUrgentContact}
          isRoleAdmin={isRoleAdmin}
          isRoleSubAdmin={isRoleSubAdmin}
          isRoleUser={isRoleUser}
          onBlur={onBlur}
          onChangeTrainingType={onChangeTrainingType}
          onCopyEmailAddress={onCopyEmailAddress}
          onDifferentInforCheckChange={onDifferentInforCheckChange}
          onDispatchTypeDetailChange={onDispatchTypeDetailChange}
          onKeyDown={onKeyDown}
          onTabClick={onTabClick}
          onWorkPlaceCityChange={onWorkPlaceCityChange}
          onWorkPlaceCountryChange={onWorkPlaceCountryChange}
          onWorkPlaceLocationChange={onWorkPlaceLocationChange}
          othersStaffNumber={othersStaffNumber}
          isPersonUrl={isPersonUrl}
          prefectures={prefectures}
          removeItems={removeItems}
          rowSelection={rowSelection}
          tabsItems={tabsItems}
        />
        <TravelersEditButtonSubmit
          form={form}
          goToTravelersDetailOrAddSuccess={goToTravelersDetailOrAddSuccess}
          isDisabledSubmit={isDisabledSubmit}
          isHiddenBtnCancel={isHiddenBtnCancel}
          isHiddenBtnSubmit={isHiddenBtnSubmit}
        />
      </Form>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
        {MESSAGE_C0005}
      </ModalConfirm>
      <Popup okText="OK" cancelText="" isOpen={isOpenPopup} onOk={onOkPopup}>
        {MESSAGE_W0014}
      </Popup>
    </Layout>
  )
}
