import { FC, Fragment } from "react"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { Translate } from "../../../../../../../../constant/Translate"
import { KanjiPassportFullName } from "../../../../../../../../_webui/layout/components/Typography/KanjiPassportFullName"
import { RomajiPassportFullName } from "../../../../../../../../_webui/layout/components/Typography/RomajiPassportFullName"
import { Title } from "../../../../../../../../_webui/layout/components/Typography/Title"
import { SchedulesAddPersonalProps } from "./models"
import { ItemSelect } from "../../../../../../../../_webui/layout/components/Form/Item/Select"
import { validatorRequiredField } from "../../../../../../../../utils/validator/requiredField"

const { TEXT_T003, TEXT_T006, TEXT_T009, TEXT_T025, TEXT_T054, MESSAGE_W0017 } =
  Translate

export const SchedulesAddPersonal: FC<SchedulesAddPersonalProps> = (props) => {
  const {
    departments,
    dispatchType,
    isAccompanyingFamily,
    isInsuranceAndUrgent,
    isOtherStaffNumber,
    onDispatchTypeDetailChange,
  } = props
  return (
    <Fragment>
      <ItemInput isReadOnly label={TEXT_T003} name="fullNameKanji" />
      <ItemInput isReadOnly label={TEXT_T006} name="fullNameRomaji" />
      <ItemInput
        isReadOnly
        label={<KanjiPassportFullName />}
        name="kanjiPassportFullName"
      />
      <ItemInput
        isReadOnly
        label={<RomajiPassportFullName />}
        name="romajiPassportFullName"
      />
      {!isOtherStaffNumber ? (
        <Fragment>
          <ItemSelect
            label={TEXT_T009}
            name="travelerBumonCd"
            required
            rules={[validatorRequiredField]}
            selectItem={departments}
          />
          <ItemSelect
            label={TEXT_T025}
            name="travelerDispatchTypeDetailId"
            onChange={onDispatchTypeDetailChange}
            required
            rules={[validatorRequiredField]}
            selectItem={dispatchType}
          />
        </Fragment>
      ) : (
        <ItemInput isReadOnly label={TEXT_T025} name="dispatchTypeDetailName" />
      )}

      {!isAccompanyingFamily && (
        <ItemInput isReadOnly label={TEXT_T054} name="workPlaceLocation" />
      )}

      {isInsuranceAndUrgent && (
        <Title type="danger" titleLeft={MESSAGE_W0017} />
      )}

      <ItemInput isReadOnly isHidden name="personEmailAddress" />
    </Fragment>
  )
}
