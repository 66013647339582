/* eslint-disable max-lines */
import { Form as FormAnt } from "antd"
import { FC } from "react"
import { ButtonSubmit } from "../../../../../../_webui/layout/components/Button/Submit"
import { Divider } from "../../../../../../_webui/layout/components/Divider"
import { Form } from "../../../../../../_webui/layout/components/Form"
import { ItemInput } from "../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../_webui/layout/components/Form/Item/Select"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { Translate } from "../../../../../../constant/Translate"
import { validatorEmailAddress } from "../../../../../../utils/validator/emailAddress"
import { validatorRequiredField } from "../../../../../../utils/validator/requiredField"
import { TravelersFormDataType, TravelersFormProps } from "../../../models/add"
import { TravelersItemInputGroup } from "./ItemInputGroup"
import { requiredField } from "../../../../../../utils/requiredField"

const {
  MESSAGE_C0001,
  TEXT_T001,
  TEXT_T002,
  TEXT_T009,
  TEXT_T012,
  TEXT_T013,
  TEXT_T017,
  TEXT_T025,
  TEXT_T278,
  TEXT_T698,
} = Translate

export const TravelersAddForm: FC<TravelersFormProps<TravelersFormDataType>> = (
  props,
) => {
  const {
    departments,
    dispatchType,
    form,
    initialValues,
    isAccompanyingFamily,
    isDisabledSubmit,
    isLoading,
    isOpen,
    onBlurUserId,
    onCancel,
    onClickReset,
    onFinish,
    onOk,
    onValuesChange,
  } = props

  const bumonCdRequired = requiredField(!isAccompanyingFamily)

  return (
    <Form
      form={form}
      heading={TEXT_T001}
      initialValues={initialValues}
      isLoading={isLoading}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <ItemInput
        label={TEXT_T002}
        maxLength={255}
        name="userId"
        onBlur={onBlurUserId}
        required
        rules={[validatorRequiredField, validatorEmailAddress]}
      />
      <Divider />
      <TravelersItemInputGroup />
      <Divider />
      <ItemSelect
        label={TEXT_T009}
        name="bumonCd"
        required={!isAccompanyingFamily}
        rules={bumonCdRequired}
        selectItem={departments}
      />
      <Divider />
      <ItemSelect
        label={TEXT_T025}
        name="dispatchTypeDetailId"
        required
        rules={[validatorRequiredField]}
        selectItem={dispatchType}
      />
      <Divider />
      <ItemInput
        extra={TEXT_T698}
        isDisabled
        label={TEXT_T017}
        maxLength={255}
        name="personEmailAddress"
      />
      <Divider />
      <ItemInput
        label={TEXT_T278}
        maxLength={255}
        name="registerRequestUserId"
        rules={[validatorEmailAddress]}
      />
      <Divider />
      <FormAnt.Item shouldUpdate className="ant-form-btn-submit">
        {() => (
          <ButtonSubmit
            isReset
            isDisabledSubmit={
              isDisabledSubmit ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
            onClickReset={onClickReset}
            resetText={TEXT_T012}
            submitText={TEXT_T013}
          />
        )}
      </FormAnt.Item>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
        {MESSAGE_C0001}
      </ModalConfirm>
    </Form>
  )
}
